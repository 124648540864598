import {
  UNCONFIRMED_BOOKING_NOTIFICATION,
  TODO_NOTIFICATION,
  RESCHEDULE_NOTIFICATION,
  EVENT_NOTIFICATION,
  TRAINING_NOTIFICATION,
  EMAIL_NOTIFICATION,
  CLIENT_CREATION_NOTIFICATION,
  REFERENCE_NOTIFICATION,
} from "../actions/types";

const initialState = {
  todo: {
    id: 0,
    message: "",
  },
  reschedule: {
    id: 0,
    message: "",
  },
  event: {
    id: 0,
    message: "",
  },
  training: {
    id: 0,
    message: "",
  },
  reference: {
    id: 0,
    message: "",
  },
  email: {
    id: 0,
    message: "",
  },
  unconfirmedBooking: {
    id: 0,
    message: "",
  },
  clientCreation: {
    id: 0,
    message: "",
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TODO_NOTIFICATION:
      return {
        ...state,
        todo: { id: action.id, message: action.message },
      };
    case RESCHEDULE_NOTIFICATION:
      return {
        ...state,
        reschedule: { id: action.id, message: action.message },
      };
    case EVENT_NOTIFICATION:
      return {
        ...state,
        event: { id: action.id, message: action.message },
      };
    case TRAINING_NOTIFICATION:
      return {
        ...state,
        training: { id: action.id, message: action.message },
      };

    case REFERENCE_NOTIFICATION:
      return {
        ...state,
        reference: { id: action.id, message: action.message },
      };
    case EMAIL_NOTIFICATION:
      return {
        ...state,
        email: { id: action.id, message: action.message },
      };
    case UNCONFIRMED_BOOKING_NOTIFICATION:
      return {
        ...state,
        unconfirmedBooking: { id: action.id, message: action.message },
      };
    case CLIENT_CREATION_NOTIFICATION:
      return {
        ...state,
        clientCreation: { id: action.id, message: action.message },
      };

    default:
      return state;
  }
}
