import { useEffect, useState } from "react";
import axios from "axios";
import useDidMountEffect from "../components/Modal/ModalBody/intialRunUseEffect";

export default function usePayroll(
  url,
  query,
  pageNumber,
  searchAdvanced,
  start,
  end,
  resetResults,
  isFromPending = false
) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [rows, setRows] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  // Reset rows on query change, searchAdvanced, or resetResults
  useEffect(() => {
    setRows([]);
  }, [query, searchAdvanced, resetResults]);

  useDidMountEffect(() => {
    setRows([]);
  }, [searchAdvanced, resetResults]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    let cancel;

    axios({
      method: "GET",
      url: url,
      params: {
        page: pageNumber,
        ...(isFromPending ? { searchQuery: query } : { searchString: query }),
        startDate: start,
        endDate: end,
      },
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    })
      .then((res) => {
        const { body } = res.data;
        if (body && body.content) {
          setTotalElements(body.totalElements || 0); // Assuming totalElements or a similar field is available
          setRows((prevRows) => [...new Set([...prevRows, ...body.content])]);
          setHasMore(body.content.length > 0);
        }
        setLoading(false);
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
        setError(true);
      });

    return () => cancel();
  }, [searchAdvanced, query, pageNumber, resetResults, url]);

  return { loading, error, rows, hasMore, totalElements };
}
