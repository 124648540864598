import React, { Component } from "react";
import "./App.css";

import {
  chatDbPostAction,
  chatArrayAction,
} from "./redux/actions/chatDbPostAction";

import {
  faHeadphones,
  faMicrophone,
  faEnvelope,
  faMicrophoneSlash,
  faPhoneSlash,
} from "@fortawesome/free-solid-svg-icons";

import {
  updateWindowSize,
  changeSideNavigation,
  updateWindowHeight,
} from "./redux/actions/appSettingsAction";

// Auth
import SetAuthorizationToken from "./util/auth/SetAuthorizationToken";

import Axios from "axios";
import SMS from "./pages/SMS/sms";
import Store, { persistedStore } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";

import { Provider } from "react-redux";
import { Device } from "twilio-client";
import Audit from "./pages/audit/audit";
import Wards from "./pages/admin/wards";
import Teams from "./pages/admin/teams";
import Bands from "./pages/admin/bands";
import { split, debounce } from "lodash";
import ChatPage from "./pages/chat/chat";
import TodoPage from "./pages/todo/todo";
import Levels from "./pages/admin/levels";
import Trusts from "./pages/admin/trusts";
import Signout from "./util/auth/Signout";
import Clients from "./pages/admin/clients";
import Login from "./pages/auth/login/login";
import Tickets from "./pages/tickets/tickets";
import Payroll from "./pages/payroll/payroll";
import Reports from "./pages/reports/reports";
// import Route from "react-router-dom/Route";
import Calling from "./pages/calling/calling";
import CallStateLabel from "./pages/calling/callState.js";
import Tranings from "./pages/admin/tranings";
import Bookings from "./pages/booking/booking";
import Drafts from "./pages/quickDraft/drafts";
import Documents from "./pages/admin/documents";
import ClientShifts from "./pages/admin/shifts";
import TicketsAdmin from "./pages/admin/tickets";
import ClientPage from "./pages/clients/clients";
import Framework from "./pages/admin/frameworks";
import requireAuth from "./util/auth/requireAuth";
import { Label, Modal } from "reactstrap";
import NotFound from "./pages/not-found/not-found";
import Trainings from "./pages/trainings/trainings";
import Dashboard from "./pages/dashboard/dashboard";
import DocView from "./pages/audit/docView/docView";
import ClientsWards from "./pages/admin/clientsWards";
import Specialities from "./pages/admin/specialities";
import TicketsSuper from "./pages/superAdmin/tickets";
import noRequireAuth from "./util/auth/noRequireAuth";
import HeadHunter from "./pages/headhunter/headhunter";
import CalendarCustom from "./pages/calendar/calendar";
import { Client as StompClient } from "@stomp/stompjs";
import EmailTemplate from "./pages/admin/emailTemplate";
// import { Client } from "twilio/lib/twiml/VoiceResponse";
import EmailBodyUI from "./pages/email/emailBodyUI";
import Employee from "./pages/employee-profile/employee";
import WelcomePage from "./pages/welcomePage/welcomePage";
// import { Offline, Online } from "react-detect-offline";
import CandidateRoles from "./pages/admin/candidateRoles";
import AssignmentCodes from "./pages/admin/AssignmentCodes";
import Maintenance from "./pages/maintainance/maintenance";
import ConsultantRoles from "./pages/admin/consultantRoles";
import Registration from "./pages/registration/registration";
import { setAuthetication, signOutAction } from "./redux/actions/authAction";
//import AuthChecker from "./components/AuthChecker/AuthChecker";
import TokenValidityCheck from "../src/util/auth/tokenExpired.js";
import candidate from "./pages/headhunter/candidates/candidate";
import ReferenceForm from "./pages/referenceForm/referenceForm";
import EmailBody from "./pages/email/consultantEmail/emailBody";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EmailLogin from "./pages/email/consultantEmail/emailLogin";
import CreditControll from "./pages/creditControll/creditControll";
import withSplashScreen from "./pages/splash-screen/splash-screen";
import CreateNewConsultant from "./pages/admin/createNewConsultant";
import HeadhunterRegister from "./pages/headhunter/register/register";
import HeadhunterRegister1 from "./pages/headhunter/register2/register1.js";
import IncomingMessageModal from "./components/Modal/incomingMessage";
import { ToastProvider, DefaultToast } from "react-toast-notifications";
import EmployeeProfile from "./pages/employee-profile/employee-profile";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import CandidateProfile from "./pages/candidate-profile/candidate-profile";
import RoundIconButton from "./components/RoundIconButton/round-icon-button";
import SetFrameworkRelationship from "./pages/admin/setFrameworkRelationship";
import {
  HOSTMexxar,
  HostMexxarWS,
  HostMexxarURL,
  HostMexxarSoftphone,
} from "./configs/api-config";
import ProfileEmployee from "./pages/candidate-profile/profile/profile-employee";
import { chatNewNotificationAction } from "./redux/actions/chatNewNotificationAction";
import BodySection from "./pages/email/consultantEmail/emailMessageContent/emailContainBody";
import {
  todoNotificationAction,
  rescheduleNotificationAction,
  eventNotificationAction,
  emailNotificationAction,
  trainingNotificationAction,
  unconfirmedBookingNotificationAction,
  clientCreationNotificationAction,
  referenceNotificationAction,
} from "./redux/actions/notificationAction";
import { Toaster } from "react-hot-toast";
import EmailConfigure from "./pages/admin/emailConfigure";
import TimeSheetScan from "./pages/payroll/TimeSheetScan";
import ForgotPasswordPage from "./pages/auth/forgot-password/forgot-password-page";
import ForgotPasswordResetPassword from "./pages/auth/forgot-password/forgot-password-reset-password";
import HeadhunterPerm from "./pages/permanentStaff/headhunterPerm";
import TraningProviders from "./pages/admin/traningsProviders";
import JobBoardPerm from "./pages/jobBoard/JobBoardPerm";
import { useNetwork } from "./customHooks/useNetwork";
import CVSources from "./pages/admin/cvSources";
import PermDocuments from "./pages/admin/permDoc";
import AppPackDocuments from "./pages/admin/AppPackDocuments";
import { msalConfig } from "./configs/authConfig.js";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import RefreeFormSucess from "./pages/referenceForm/refreeFormSuccess";
import OutlookUserProfileData from "./pages/email/OutlookUserProfileData";
import TimeSheetBatchScan from "./pages/payroll/TimeSheetBatchScan";
import axios from "axios";
import { useEffect } from "react";
import store from "./redux/store";
import { storeWebSocketData } from "./redux/actions/softPhoneIntegrationAction.js";
import IncomingcallModal from "./components/Modal/incomingCall.js";
import TimeSheetBatchScan2 from "./pages/payroll/TimeSheetBatchScan2.js";
import MissedcallModal from "./components/Modal/missedCall.js";
import MissedCallHistory from "./widgets/header/missedCallHistory.js";
import { connect } from "react-redux";
import CandyTimeSheetBatchScan from "./pages/payroll/CandyTimeSheetBatchScan.js";
import BankHoliday from "./pages/admin/BankHoliday/index.js";
import onboarding from "./pages/candidate-profile/onboarding/onboarding.js";
import BulkReports from "./pages/bulk-reports/bulkReports.js";
import CreditSystemSuper from "./pages/superAdmin/creditsystem/index.js";
import MobileTimeSheetScan from "./pages/payroll/MobileTimeSheetScan.js";
import TransactionSuper from "./pages/superAdmin/transactiontype/index.js";

import NotificationSetting from "./pages/admin/notificationsetting/index.js";
import RateGrades from "./pages/admin/GradesForRates/index.js";

const msalInstance = new PublicClientApplication(msalConfig);

const getApiToken = () => {
  const MexxarApiHost = HOSTMexxar;
  Axios.get(MexxarApiHost + "twillio/generate-token")
    .then(async (data) => {
      Device.setup(data.data.body[0]);
      //console.log("twillio generate-token", data.data.body[0]);
    })
    .catch((err) => {
      //Todo: Do Your stuff here
    });
};

// useEffect(() => {
//   Register();
// }, []);
// var setState=(state, current)=>{
//   state.setState({showCallModal: !current});
// }

// var modelShowRef, customThis;

//custom toast notifier view
const MyCustomToast = ({ children, ...props }) => {
  let temp = split(children, ":");

  let splitedtemp = temp[0];

  return (
    <div>
      {splitedtemp === "New Message from " ? (
        <div
          className="d-flex flex-column "
          style={{
            height: "100px",
            width: "300px",
            backgroundColor: "#DDDDDD",
            borderRadius: "5px",
          }}
        >
          <div
            className="d-flex align-items-center  "
            style={{
              color: "#676767",
              fontSize: "13px",
              height: "30px",
              width: "300px",
              backgroundColor: "#EEEFEF",
              borderTopRightRadius: "5px",
              borderTopLeftRadius: "5px",
            }}
          >
            <FontAwesomeIcon
              className="mx-2 "
              icon={faEnvelope}
              color="#31c971"
            />{" "}
            Messages
          </div>
          <div
            className="mx-2 text-dark text-bold"
            style={{ fontSize: "15px" }}
          >
            {temp[1]}
          </div>

          <div className="mx-2 text-dark " style={{ fontSize: "13px" }}>
            {temp[2]}
          </div>
        </div>
      ) : (
        <DefaultToast {...props}>
          <div>{children}</div>
        </DefaultToast>
      )}
    </div>
  );
};

// const MyCustomToast = ({ children, ...props }) => (

//       const MexxarApiHost = HOSTMexxar;

// //  temp =  split(children, ":*:*:");

// //   <DefaultToast {...props}>
// // {children}
// //     <div>{children} {console.log("children" ,children)}</div>
// //   </DefaultToast>
// );

class App extends Component {
  state = { showCallModal: false, callConn: {}, mute: false };

  modelShowRef = React.createRef(this.state.showCallModal);
  // customThis = React.createRef(this);
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      client: "",
      incomingMessageModal: false,
    };

    //this.state = {endTime: ""};

    // const authToken = useSelector((state) => state.authReducer1.authToken);

    const { horizonId } = this.props;

    if (localStorage.jwtToken) {
      SetAuthorizationToken(localStorage.jwtToken);
      Store.dispatch(setAuthetication(JSON.parse(localStorage.user)));
      getApiToken();
    }

    // if (authToken) {
    //   SetAuthorizationToken(authToken);
    //   Store.dispatch(setAuthetication(loggedInUser));
    //   // getApiToken();
    // } else if (localStorage.jwtToken) {
    //   SetAuthorizationToken(localStorage.jwtToken);
    //   Store.dispatch(setAuthetication(JSON.parse(localStorage.user)));
    //   getApiToken();
    // }

    Store.dispatch(updateWindowHeight(window.innerHeight));

    Device.disconnect((event) => {
      // setCallingStatus("Call ended.");
      this.setState({ showCallModal: false });
      // clearInterval(timer);
    }, []);

    Device.on("error", (error) => {
      // setCallingStatus("Phone not set properly, Please refresh the page");
      // setIsCalling(false);
      // alert("error");
      this.setState({ showCallModal: false });
      console.log("Phone error");
    });

    Device.on("connect", function (conn) {
      // setIsCalling(true);
      // setCallingStatus("Ringing...");
      console.log("Phone ringing");
    });

    Device.on("cancel", (conn) => {
      // setIsCalling(true);
      // setCallingStatus("Ringing...");

      this.setState({ showCallModal: false });
    });

    Device.on("disconnect", (conn) => {
      // setTimeShow("");
      // clearInterval(timer);
      // setCallingStatus("Canceled call");
      // setIsCalling(false);
      // this.setState({endTime: new Date()});
      //alert("Disconnect");
      this.setState({ showCallModal: false });
      console.log("Phone disconnected");
    });

    Device.on("ready", function (conn) {
      //handleOnClick();
      //console.log(conn);
      console.log("Phone ready");
    });

    Device.incoming((conn) => {
      //var r = window.confirm("Incoming call...");

      //  this.setState({showCallModal: true})
      // setState(customThis, modelShowRef);
      // if (r == true) {
      //   conn.accept();
      // } else {
      //   conn.reject();
      // }
      // this.modelShowRef(true)
      console.log("incomming");
      this.setState({ showCallModal: true, callConn: conn });
      //console.log("Incoming connection from " + conn.parameters.From);
      // accept the incoming connection and start two-way audio
      //conn.accept();
    });

    if (localStorage.user !== undefined) {
      // -------email notifications-----------------------------------------

      this.emailClient = new StompClient();
      this.emailClient.configure({
        brokerURL: "mailapi.mexxar.com/wsc",
        onConnect: () => {
          this.emailClient.subscribe(`/email`, (message) => {
            var quote = JSON.parse(message.body);
            Store.dispatch(emailNotificationAction(quote));

            console.log("email notifier", quote);
          });
        },
      });

      // -------chat payload- with notifications----------------------------

      this.client = new StompClient();
      this.client.configure({
        brokerURL: HostMexxarWS + "wsc",
        onConnect: () => {
          console.log("onConnect");
          if (JSON.parse(localStorage.user).userType === "EMPLOYEE") {
            Axios.get(
              `${HOSTMexxar}employee/${JSON.parse(localStorage.user).id}`
            ).then((res) => {
              if (res.data.body[0] !== undefined) {
                this.client.subscribe(
                  `/message/${res?.data?.body[0]?.companyPhoneNumber}`,
                  (message) => {
                    // this.setState({serverTime: message.body});
                    this.setState({ incomingMessageModal: true });
                    var audio = new Audio(
                      `${HostMexxarURL}tones/smsIncoming.ogg`
                    );
                    audio.play();
                  }
                );
              }
            });
          }

          this.client.subscribe(
            `/todo/${JSON.parse(localStorage.user).id}`,
            (message) => {
              var quote = JSON.parse(message.body);
              Store.dispatch(todoNotificationAction(quote));
            }
          );
          this.client.subscribe(`/booking/unconfirmed/`, (message) => {
            var quote = JSON.parse(message.body);
            Store.dispatch(unconfirmedBookingNotificationAction(quote));
          });
          this.client.subscribe(
            `/reschedule/${JSON.parse(localStorage.user).id}`,
            (message) => {
              var quote = JSON.parse(message.body);
              Store.dispatch(rescheduleNotificationAction(quote));
            }
          );
          this.client.subscribe(
            `/event/${JSON.parse(localStorage.user).id}`,
            (message) => {
              var quote = JSON.parse(message.body);
              Store.dispatch(eventNotificationAction(quote));
            }
          );
          this.client.subscribe(
            `/training/${JSON.parse(localStorage.user).id}`,
            (message) => {
              var quote = JSON.parse(message.body);
              Store.dispatch(trainingNotificationAction(quote));
            }
          );

          this.client.subscribe(
            `/Reference/${JSON.parse(localStorage.user).id}`,
            (message) => {
              var quote = JSON.parse(message.body);
              console.log("quote from client subs (reference):", quote);
              Store.dispatch(referenceNotificationAction(quote));
            }
          );

          this.client.subscribe(
            `/chat/${JSON.parse(localStorage.user).id}`,
            (message) => {
              Store.dispatch(chatArrayAction(JSON.parse(message.body).roomId));
              if (
                JSON.parse(message.body).from !==
                JSON.parse(localStorage.user).id
              ) {
                var audio = new Audio(`${HostMexxarURL}tones/messageTone.ogg`);
                audio.play();

                // let splitOne = split(message.body, ",");
                // let splitTwo = split(splitOne[2], ":");
                // let splitedMessage = splitTwo[1];

                Store.dispatch(
                  chatNewNotificationAction(
                    Number(JSON.parse(message.body).from),
                    JSON.parse(message.body).message
                  )
                );
              }
            }
          );

          this.client.subscribe(
            `/maintenance/summary/${JSON.parse(localStorage.user).id}`,
            (message) => {
              var quote = JSON.parse(message.body);
              console.warn("quote from client subs (maintenance):", quote);
            }
          );

          this.client.subscribe(`/clients`, (message) => {
            var quote = JSON.parse(message.body);
            Store.dispatch(clientCreationNotificationAction(quote));
            console.warn("quote from client subs (clients):", quote);
          });
        },
      });
      this.client.activate();

      // Creating a WebSocket connection
      const socket = new WebSocket(
        `wss://localhost:10443/api/v1/events/${horizonId?.customHeaders?.horizonApplicationID}`
      );

      socket.onopen = () => {
        console.log("WebSocket connection established");
      };

      socket.onmessage = (event) => {
        console.log("Received message:", event.data);

        // Parse the incoming WebSocket data
        const eventData = JSON.parse(event.data);

        if (eventData.EventType === "CallsChanged") {
          // Extract the CallState from the payload
          const callState = eventData.Payload?.Calls[0]?.CallState;
          const Direction = eventData.Payload?.Calls[0]?.CallerContact;
          const IncomingUniqueId = eventData.Payload?.Calls[0]?.UniqueId;

          //  console.log("Direction call",calls)

          console.log("IncomingUniqueId", IncomingUniqueId);
          // this.setState({ Direction });
          // localStorage.setItem('Direction', Direction);
          console.log("incoming call", Direction);

          // Update your UI label based on the CallState
          if (callState) {
            this.setState({ callState });
            localStorage.setItem("callState", callState);
          }
          if (eventData.Payload?.Calls[0]?.Direction === "Incoming") {
            this.setState({ IncomingUniqueId });
            this.setState({ Direction });
            console.log("hiiiiiii", Direction);
          }
        }

        if (eventData.EventType === "CallHistoryChanged") {
          console.log("History call", eventData?.CallHistory[0]);

          const callHistory = eventData?.CallHistory[0]?.StartTimeUtc;
          const callHistoryDuration = eventData?.CallHistory[0]?.Duration;
          const missedCall = eventData?.Missed[0]?.CallerContact;
          const calls = eventData?.Missed;
          console.log("Missed Call", missedCall);
          console.log("Calls", calls);
          // eventDate.readRecipt=false

          // localStorage.setItem(
          //   "missedCallHistory",
          //   JSON.stringify(Array.from(calls.entries()))
          // );
          localStorage.setItem("missedCallHistory", JSON.stringify(calls));

          const eventType = eventData;
          console.log("event type", eventType);

          this.setState({ callHistory });

          this.setState({ missedCall });

          console.log("History call start time", callHistory);
          console.log("callHistoryDuration", callHistoryDuration);
          localStorage.setItem("callHistory", callHistory);
          localStorage.setItem(
            "callHistoryDuration",
            JSON.stringify(callHistoryDuration)
          );
          // localStorage.setItem("callHistoryDuration", eventType);
        }
      };

      socket.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      socket.onclose = (event) => {
        console.log("WebSocket connection closed:", event);
      };

      Axios.get(HOSTMexxar + "chat/my-chats").then((res) => {
        //console.log("onConnect");
        Store.dispatch(chatDbPostAction(res.data.body));
      });

      //***********notifications web sockets********************//

      // //events notifier
      // this.client = new StompClient();
      // this.client.configure({
      //   brokerURL: HostMexxarWS + "wsc",
      //   onConnect: () => {
      //     this.client.subscribe(
      //       `/event/${JSON.parse(localStorage.user).id}`,
      //       () => {
      //         // setReset(true);
      //         console.log("event notifier");
      //       }
      //     );
      //   },
      // });
      // this.client.activate();
    }
  }
  // ------------------------------------------------------------
  componentDidMount() {
    window.addEventListener(
      "resize",
      debounce(() => {
        Store.dispatch(updateWindowSize(window.innerWidth));
        Store.dispatch(updateWindowHeight(window.innerHeight));

        if (window.innerWidth < 992) {
          Store.dispatch(changeSideNavigation(false));
          console.log("angi windows inner width-if-->", window.innerWidth);
        } else {
          console.log("angi windows inner width-else-->", window.innerWidth);

          Store.dispatch(changeSideNavigation(true));
        }
      }, 200)
    );
  }

  logOut() {
    Store.dispatch(signOutAction());
  }

  render() {
    return (
      <Provider store={Store}>
        <PersistGate loading={null} persistor={persistedStore}>
          <MsalProvider instance={msalInstance}>
            <div></div>
            {/* <AuthChecker /> */}

            {/* <CallStateLabel /> */}
            <Modal
              isOpen={this.state.showCallModal}
              //toggle={toggle}
              className="text-gray "
              centered={true}
              style={{ width: "350px" }}
              backdrop={true}
              keyboard={true}
            >
              <div className="p-3">
                <div>
                  <div className="d-flex justify-content-center">
                    <RoundIconButton
                      buttonColor="#28a745"
                      icon={faHeadphones}
                      iconSize="fa-m"
                      buttonSize="btn-lg"
                      click={() => this.state.callConn.accept()}
                    />
                  </div>

                  <div className="d-flex justify-content-center">
                    <Label>Incomming Call</Label>
                  </div>

                  <div
                    className="d-flex justify-content-center"
                    style={{ flex: 2 }}
                  >
                    <div
                      className="d-flex justify-content-center"
                      style={{ flex: 1 }}
                    >
                      <RoundIconButton
                        buttonColor={`${
                          !this.state.mute ? "#f31f7f" : "#31c971"
                        }`}
                        icon={
                          this.state.mute ? faMicrophone : faMicrophoneSlash
                        }
                        iconSize="fa-sm"
                        buttonSize="btn-sm"
                        click={() => {
                          Device.mute(!this.state.mute);
                          this.setState({ mute: !this.state.mute });
                        }}
                      />
                    </div>
                    <div
                      className="d-flex justify-content-center"
                      style={{ flex: 1 }}
                    >
                      <RoundIconButton
                        buttonColor="#dc3545"
                        icon={faPhoneSlash}
                        iconSize="fa-sm"
                        buttonSize="btn-sm"
                        click={() => {
                          this.state.callConn.reject();
                          this.setState({ showCallModal: false });
                          Device.disconnectAll();
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
            {/* <CallStateLabel /> */}
            {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
  <p style={{ textAlign: 'center' }}> {this.state.callState}</p>
</div> */}
            {/* <Modal> */}
            {/* <div className="d-flex justify-content-center">
          <Label>Incoming Call</Label>
        </div>
        <div className="d-flex justify-content-center">
          <p>{this.state.Direction?.DialString}</p>
          <p>{this.state.Direction?.DisplayName}</p>
          {/* Display other call data properties similarly */}
            {/* </div> */}
            {/* </Modal> */}
            <ToastProvider
              components={{ Toast: MyCustomToast }}
              placement="bottom-right"
            >
              <Toaster />
              <Router>
                <Switch>
                  {/* ReferenceForm */}
                  <Route
                    path="/referenceForm"
                    component={noRequireAuth(ReferenceForm)}
                  />
                  <Route
                    path="/refreeFormSuccess/:referee/:candidate"
                    component={noRequireAuth(RefreeFormSucess)}
                  />
                  <Route exact path="/" component={noRequireAuth(Login)} />
                  <Route
                    exact
                    path="/forgot-password/forgot-password-page"
                    component={noRequireAuth(ForgotPasswordPage)}
                  />
                  <Route
                    exact
                    path="/forgot-password/forgot-password-reset-password"
                    component={noRequireAuth(ForgotPasswordResetPassword)}
                  />
                  <Route
                    exact
                    path="/auth/logout"
                    component={requireAuth(Signout)}
                  />
                  <Route
                    exact
                    path="/auth/login"
                    component={noRequireAuth(Login)}
                  />
                  <Route
                    exact
                    path="/dashboard"
                    component={requireAuth(Dashboard)}
                  />
                  <Route
                    exact
                    path="/dashboard/headhunterPerm"
                    component={requireAuth(HeadhunterPerm)}
                  />
                  {/*  Headhunter User Tasks  */}
                  <Route
                    exact
                    path="/dashboard/headhunter"
                    component={requireAuth(HeadHunter)}
                  />
                  <Route
                    exact
                    path="/dashboard/onboarding"
                    component={requireAuth(onboarding)}
                  />
                  <Route
                    exact
                    path="/dashboard/candidates"
                    component={requireAuth(candidate)}
                  />
                  <Route
                    exact
                    path="/dashboard/candidates/register"
                    component={requireAuth(HeadhunterRegister)}
                  />
                  <Route
                    exact
                    path="/dashboard/candidates/register1"
                    component={requireAuth(HeadhunterRegister1)}
                  />
                  {/*  End of Headhunter User Tasks  */}
                  {/* Booking user tasks */}
                  <Route
                    exact
                    path="/dashboard/booking"
                    component={requireAuth(Bookings)}
                  />
                  {/* End of Booking User Tasks */}
                  {/* Employee user tasks */}
                  <Route
                    exact
                    path="/dashboard/employee"
                    component={requireAuth(Employee)}
                  />
                  {/* End of Employee User Tasks */}
                  <Route
                    exact
                    path="/dashboard/call/:id"
                    component={requireAuth(Calling)}
                    // endTime={this.state.endTime}
                  />
                  <Route
                    path="/dashboard/candidate-profile/:id/:tp/:tab?"
                    component={requireAuth(CandidateProfile)}
                  />
                  <Route
                    path="/dashboard/employee-profile/:id"
                    component={requireAuth(ProfileEmployee)}
                  />
                  <Route
                    exact
                    path="/dashboard/employee-profile/:id"
                    component={requireAuth(EmployeeProfile)}
                  />
                  <Route
                    exact
                    path="/dashboard/audit"
                    component={requireAuth(Audit)}
                  />
                  <Route
                    exact
                    path="/dashboard/maintenance"
                    component={requireAuth(Maintenance)}
                  />
                  <Route
                    exact
                    path="/dashboard/registration"
                    component={requireAuth(Registration)}
                  />
                  <Route
                    exact
                    path="/dashboard/docview/:incommingPage/:candidateId/:docId/:candidateRole/:changedSpecility/:changedFramework"
                    component={requireAuth(DocView)}
                  />
                  <Route
                    exact
                    path="/dashboard/chat"
                    component={requireAuth(ChatPage)}
                  />
                  <Route
                    exact
                    path="/dashboard/email/loginPage"
                    component={requireAuth(EmailLogin)}
                  />
                  <Route
                    exact
                    path="/dashboard/email/:mailbox/view/:timestamp/:id"
                    component={requireAuth(BodySection)}
                  />
                  <Route
                    exact
                    path="/dashboard/email/inbox"
                    component={requireAuth(EmailBody)}
                  />
                  <Route
                    exact
                    path="/dashboard/outlook"
                    component={requireAuth(EmailBody)}
                  />
                  <Route
                    exact
                    path="/dashboard/outlook/loginPage"
                    component={requireAuth(EmailLogin)}
                  />
                  <Route
                    exact
                    path="/dashboard/todo"
                    component={requireAuth(TodoPage)}
                  />
                  <Route
                    exact
                    path="/dashboard/clients"
                    component={requireAuth(ClientPage)}
                  />
                  <Route
                    exact
                    path="/dashboard/drafts"
                    component={requireAuth(Drafts)}
                  />
                  <Route
                    exact
                    path="/dashboard/payroll"
                    component={requireAuth(Payroll)}
                  />
                  <Route
                    exact
                    path="/payroll/scanTimeSheets"
                    component={requireAuth(TimeSheetScan)}
                  />
                  <Route
                    exact
                    path="/payroll/scanTimeSheetsBatches"
                    component={requireAuth(TimeSheetBatchScan)}
                  />
                  <Route
                    exact
                    path="/payroll/scanTimeSheetsBatches2"
                    component={requireAuth(TimeSheetBatchScan2)}
                  />
                  <Route
                    exact
                    path="/payroll/candyscanTimeSheetsBatches"
                    component={requireAuth(CandyTimeSheetBatchScan)}
                  />
                  <Route
                    exact
                    path="/payroll/mobileTimesheetScan"
                    component={requireAuth(MobileTimeSheetScan)}
                  />
                  <Route
                    exact
                    path="/dashboard/tickets"
                    component={requireAuth(Tickets)}
                  />
                  <Route
                    exact
                    path="/dashboard/creditControll"
                    component={requireAuth(CreditControll)}
                  />
                  <Route
                    exact
                    path="/dashboard/reports"
                    component={requireAuth(Reports)}
                  />
                  <Route
                    exact
                    path="/dashboard/bulk-reports"
                    component={requireAuth(BulkReports)}
                  />
                  <Route
                    exact
                    path="/dashboard/calendar"
                    component={requireAuth(CalendarCustom)}
                  />
                  <Route
                    exact
                    path="/dashboard/trainings"
                    component={requireAuth(Trainings)}
                  />
                  <Route
                    exact
                    path="/dashboard/jobBoardPerm"
                    component={requireAuth(JobBoardPerm)}
                  />
                  {/* Admin Pages */}
                  <Route
                    exact
                    path="/dashboard/admin/assignmentCodes"
                    component={requireAuth(AssignmentCodes)}
                  />{" "}
                  <Route
                    exact
                    path="/dashboard/admin/rateGrades"
                    component={requireAuth(RateGrades)}
                  />
                  <Route
                    exact
                    path="/dashboard/admin/candidateRoles"
                    component={requireAuth(CandidateRoles)}
                  />
                  <Route
                    exact
                    path="/dashboard/admin/consultantRoles"
                    component={requireAuth(ConsultantRoles)}
                  />
                  <Route
                    exact
                    path="/dashboard/admin/BankHoliday"
                    component={requireAuth(BankHoliday)}
                  />
                  <Route
                    exact
                    path="/dashboard/admin/tickets"
                    component={requireAuth(TicketsAdmin)}
                  />
                  <Route
                    exact
                    path="/dashboard/superadmin/tickets"
                    component={requireAuth(TicketsSuper)}
                  />
                  <Route
                    exact
                    path="/dashboard/superadmin/creditsystem"
                    component={requireAuth(CreditSystemSuper)}
                  />
                  <Route
                    exact
                    path="/dashboard/superadmin/transactiontype"
                    component={requireAuth(TransactionSuper)}
                  />
                  <Route
                    exact
                    path="/dashboard/admin/wards"
                    component={requireAuth(Wards)}
                  />
                  <Route
                    exact
                    path="/dashboard/admin/client-wards/:clientId"
                    component={requireAuth(ClientsWards)}
                  />
                  <Route
                    exact
                    path="/dashboard/admin/bands"
                    component={requireAuth(Bands)}
                  />
                  <Route
                    exact
                    path="/dashboard/admin/emailTemplate"
                    component={requireAuth(EmailTemplate)}
                  />
                  <Route
                    exact
                    path="/dashboard/admin/emailConfigure"
                    component={requireAuth(EmailConfigure)}
                  />
                  <Route
                    exact
                    path="/dashboard/admin/teams"
                    component={requireAuth(Teams)}
                  />
                  <Route
                    exact
                    path="/dashboard/admin/trainings"
                    component={requireAuth(Tranings)}
                  />
                  <Route
                    exact
                    path="/dashboard/admin/trainingproviders"
                    component={requireAuth(TraningProviders)}
                  />{" "}
                  <Route
                    exact
                    path="/dashboard/admin/cvsources"
                    component={requireAuth(CVSources)}
                  />
                  <Route
                    exact
                    path="/dashboard/admin/permDocuments"
                    component={requireAuth(PermDocuments)}
                  />
                  <Route
                    exact
                    path="/dashboard/sms"
                    component={requireAuth(SMS)}
                  />
                  <Route
                    exact
                    path="/dashboard/admin/specialities"
                    component={requireAuth(Specialities)}
                  />
                  <Route
                    exact
                    path="/dashboard/admin/frameworks"
                    component={requireAuth(Framework)}
                  />
                  <Route
                    exact
                    path="/dashboard/admin/levels"
                    component={requireAuth(Levels)}
                  />
                  <Route
                    exact
                    path="/dashboard/admin/clients"
                    component={requireAuth(Clients)}
                  />
                  <Route
                    exact
                    path="/dashboard/admin/documents"
                    component={requireAuth(Documents)}
                  />
                  <Route
                    exact
                    path="/dashboard/admin/appPackDocuments"
                    component={requireAuth(AppPackDocuments)}
                  />
                  <Route
                    exactnpm
                    path="/dashboard/admin/createNewConsultant"
                    component={requireAuth(CreateNewConsultant)}
                  />
                  <Route
                    exact
                    path="/dashboard/admin/frameworkRelationship"
                    component={requireAuth(SetFrameworkRelationship)}
                  />
                  <Route
                    exact
                    path="/dashboard/admin/trusts"
                    component={requireAuth(Trusts)}
                  />
                  <Route
                    exact
                    path="/dashboard/admin/notificationsetting"
                    component={requireAuth(NotificationSetting)}
                  />
                  <Route
                    exact
                    path="/dashboard/admin/shifts"
                    component={requireAuth(ClientShifts)}
                  />
                  <Route
                    exact
                    path="/dashboard/candidate/application/welcome"
                    component={noRequireAuth(WelcomePage)}
                  />
                  <Route
                    exact
                    path="/candidate/application/welcome"
                    component={requireAuth(ClientShifts)}
                  />
                  {/* <Route
                exact
                path="/sample"
                component={requireAuth(EmailBodyUI)}
              /> */}
                  <Route
                    exact
                    path="/dashboard/email/outlook/inbox"
                    component={requireAuth(EmailBodyUI)}
                  />
                  <Route exact path="**" component={noRequireAuth(NotFound)} />
                  {/*  signout if the aJWT token expired */}
                  <TokenValidityCheck logOut={this.logOut} />
                </Switch>

                {/* <Calling callHistory={this.state.callHistory}  callHistoryData={{
                    startTime: this.state.callHistory?.StartTimeUtc,
                    duration: this.state.callHistory?.Duration,
                    // IncomingUniqueId:this.state.IncomingUniqueId
                    // Other properties you want to display
                  }}/> */}

                {/* <IncomingcallModal
                  show= {this.state.Direction}
                  closeModal={(data) =>
                    this.setState({ Direction: data })
                  }
                ></IncomingcallModal> */}

                {/* <MissedCallHistory
                  //  headers1={this.state.eventType}
                  show={this.state?.missedCall} // or a variable controlling modal visibility
                  closeModal={(data) => this.setState({ missedCall: data })}
                  missedCallData={{
                    DialString: this.state?.missedCall?.DialString,
                    DisplayName: this.state?.missedCall?.DisplayName,
                    IncomingUniqueId: this.state?.IncomingUniqueId,
                    // IsAnswered:this.state.Direction?.Direction,
                    // Other properties you want to display
                  }}
                /> */}

                {/* 
                <MissedcallModal
                  //  headers1={this.state.eventType}
                  show={this.state?.missedCall} // or a variable controlling modal visibility
                  closeModal={(data) => this.setState({ calls: data })}
                  missedCallData={{
                    DialString: this.state?.missedCall?.DialString,
                    DisplayName: this.state?.missedCall?.DisplayName,
                    IncomingUniqueId: this.state?.IncomingUniqueId,
                    // IsAnswered:this.state.Direction?.Direction,
                    // Other properties you want to display
                  }}
                /> */}
                <IncomingcallModal
                  headers1={this.state?.eventType}
                  show={this.state?.Direction} // or a variable controlling modal visibility
                  closeModal={(data) => this.setState({ Direction: data })}
                  directionData={{
                    DialString: this.state.Direction?.DialString,
                    DisplayName: this.state.Direction?.DisplayName,
                    IncomingUniqueId: this.state?.IncomingUniqueId,
                    IsAnswered: this.state.Direction?.Direction,
                    // Other properties you want to display
                  }}
                />

                <IncomingMessageModal
                  show={this.state?.incomingMessageModal}
                  closeModal={(data) =>
                    this.setState({ incomingMessageModal: data })
                  }
                ></IncomingMessageModal>
              </Router>
            </ToastProvider>
          </MsalProvider>
        </PersistGate>
      </Provider>
    );
  }
}

function mapStateToProps(state) {
  return {
    userId: state?.auth?.item?.id,
    theme: state?.theme?.theme,
    reduxChatScriptId: state?.ChatRedux?.chatfunkyId,
    // authToken: state?.authReducer1?.authToken,
    // loggedInUser: state?.authReducer1?.loggedInUser,
    horizonId: state?.customHeaderReducer,
  };
}

const ConnectedApp = connect(mapStateToProps)(App);
export default withSplashScreen(ConnectedApp);
