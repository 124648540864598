import {
  SAVE_TIMESHEETS_LOCALLY,
  REMOVE_TIMESHEETS_LOCALLY_ALL,
  REMOVE_TIMESHEETS_LOCALLY_ONE,
  REMOVE_TIMESHEETS_LOCALLY_ONE_BEFORE_PROCESS,
  GET_LOCAL_TIMESHEETS,
  TIME_SHEET_EXIST_OR_NOT,
  ADD_AI_TIMESHEET_DATA,
  ADD_SYSTEM_TIMESHEET_DATA,
  UPDATE_TIMESHEET_REVIEWED_DATA,
  UPDATE_TIMESHEET_REVIEWED_DATA_CANDY,
  UPDATE_TIMESHEET_SCAN_PROCESSING_STATUS,
  LOG_FAILED_TIMESHEETS,
  SCAN_STATUS_INDIVIDUAL,
  UPDATE_TIMESHEET_AI_REVIEWED_STATUS,
  UPDATE_TIMESHEET_AI_REVIEWED_STATUS_CANDY,
  FINAL_MANUALLY_REVIEW_CONFIRMATION,
  UPDATE_TIMESHEET_S3BUCKETFILEURL,
  SAVE_GENERATED_URL,
  UPDATE_TIMESHEET_NAME_AND_EXTENTION,
  UPDATE_TIMESHEET_URL,
  PRE_VERIFIED_STATUS,
} from "../actions/types";

const initialState = {
  timesheets: [],
  scanProcessingStatus: "NOT_STARTED", //"NOT_STARTED, "SCANNING","DONE","INTERRUPTED"
  failedTimesheets: [],
  finalManualReviewConfirmation: "NOT_CONFIRMED",
  generatedUrl: "", //  "NOT_CONFIRMED" ,"CONFIRMED"
  exportObject: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_TIMESHEETS_LOCALLY:
      // return {
      //   ...state,
      //   timesheets: [...state.timesheets, ...action.payload],
      // };
      // return {
      //   timesheets: [...action.payload],
      // };
      return {
        ...state,
        timesheets: [...action.payload],
      };

    case REMOVE_TIMESHEETS_LOCALLY_ALL:
      return {
        ...initialState,
      };

    case REMOVE_TIMESHEETS_LOCALLY_ONE:
      return {
        ...state,
        timesheets: state.timesheets.filter(
          (timesheet) => timesheet?.DBData?.referenceNo !== action?.payload
        ),
      };
    case REMOVE_TIMESHEETS_LOCALLY_ONE_BEFORE_PROCESS:
      return {
        ...state,
        timesheets: state.timesheets.filter(
          (timesheet) => timesheet?.file?.preview !== action?.payload
        ),
      };

    case TIME_SHEET_EXIST_OR_NOT:
      const { timeSheetExist } = action.payload;

      return {
        ...state,
        timesheets: state.timesheets.map((timesheet) =>
          timesheet.file.preview === action.payload.id
            ? { ...timesheet, timeSheetExist }
            : timesheet
        ),
      };
    case UPDATE_TIMESHEET_REVIEWED_DATA:
      const { data } = action.payload;

      return {
        ...state,
        timesheets: state.timesheets.map((timesheet) =>
          timesheet.file.preview === action.payload.id
            ? { ...timesheet, data, manuallyReviewed: true }
            : timesheet
        ),
      };

    // case UPDATE_TIMESHEET_REVIEWED_DATA_CANDY: {
    //   const { id, reviewedData, timesheetReviewDateTime } = action.payload;

    //   console.log(
    //     "edge reviewedData, timesheetReviewDateTime",
    //     reviewedData,
    //     timesheetReviewDateTime
    //   );

    //   // Check if any of the rows match the reference number
    //   const existingTimesheets = state?.timesheets?.filter(
    //     (timesheet) => timesheet.DBData.referenceNo === id
    //   );
    //   console.log("edge existingTimesheets,  ", existingTimesheets);

    //   // If entries already exist, update them; otherwise, add new entries
    //   const updatedTimesheets = reviewedData?.map((newData) => {
    //     const matchingTimesheet = existingTimesheets.find(
    //       (timesheet) => timesheet.rateType === newData.rateType
    //     );

    //     console.log("edge matchingTimesheet,  ", matchingTimesheet);
    //     console.log("edge newData,  ", newData);

    //     return matchingTimesheet
    //       ? {
    //           ...matchingTimesheet,
    //           reviewedData: newData,
    //           manuallyReviewed: true,
    //           timesheetReviewDateTime,
    //         }
    //       : {
    //           DBData: { referenceNo: id },
    //           reviewedData: newData,
    //           manuallyReviewed: true,
    //           timesheetReviewDateTime,
    //         };
    //   });

    //   // Merge the updated timesheets back into the state, preserving others
    //   return {
    //     ...state,
    //     timesheets: [
    //       ...state.timesheets.filter(
    //         (timesheet) => timesheet.DBData.referenceNo !== action.payload.id
    //       ),
    //       ...updatedTimesheets,
    //     ],
    //   };
    // }

    case UPDATE_TIMESHEET_REVIEWED_DATA_CANDY: {
      console.log("action.payload:", action);

      const { id, reviewedData, timesheetReviewDateTime } = action.payload;

      console.log(
        "edge UPDATE_TIMESHEET_REVIEWED_DATA_CANDY--->",
        reviewedData,
        timesheetReviewDateTime,
        id
      );

      // Check if any of the rows match the reference number
      const existingTimesheets = state?.exportObject?.filter(
        (data) => data.Reference === id
      );

      // If entries already exist, update them; otherwise, add new entries
      const exportingTimesheets = reviewedData?.map((newData) => {
        const matchingTimesheet = existingTimesheets.find(
          (timesheet) => timesheet.PayDescription === newData.PayDescription
        );

        return matchingTimesheet
          ? {
              ...matchingTimesheet, // Use existing properties
              ...newData, // Spread newData to replace specific fields
            }
          : {
              ...newData, // Spread newData to include all its fields
            };
      });

      return {
        ...state,
        // Keep timesheets unchanged except for setting reviewedData on the matching timesheet
        timesheets: state.timesheets.map((timesheet) =>
          timesheet?.DBData?.referenceNo === id
            ? {
                ...timesheet,
                reviewedData: reviewedData[0], // Assuming you want only the first element
                manuallyReviewed: true,
                timesheetReviewDateTime,
              }
            : timesheet
        ),
        exportObject: [
          ...state?.exportObject.filter(
            (data) => data.Reference !== id // Remove old entries with the same reference
          ),
          ...exportingTimesheets, // Add the updated/new entries
        ],
      };
    }

    // case UPDATE_TIMESHEET_REVIEWED_DATA_CANDY:
    //   const { reviewedData, timesheetReviewDateTime } = action.payload;

    //   return {
    //     ...state,
    //     timesheets: state.timesheets.map((timesheet) =>
    //       timesheet.DBData.referenceNo === action.payload.id
    //         ? {
    //             ...timesheet,
    //             reviewedData,
    //             manuallyReviewed: true,
    //             timesheetReviewDateTime: timesheetReviewDateTime,
    //           }
    //         : timesheet
    //     ),
    //   };
    case UPDATE_TIMESHEET_NAME_AND_EXTENTION:
      const { fileName, fileExtention } = action.payload;

      return {
        ...state,
        timesheets: state.timesheets.map((timesheet) =>
          timesheet.file.preview === action.payload.id
            ? { ...timesheet, fileName: fileName }
            : timesheet
        ),
      };
    case UPDATE_TIMESHEET_URL:
      const { s3BucketUrl } = action.payload;
      return {
        ...state,
        timesheets: state.timesheets.map((timesheet) =>
          timesheet.file.preview === action.payload.id
            ? { ...timesheet, s3bucketfileurl: s3BucketUrl }
            : timesheet
        ),
      };

    case UPDATE_TIMESHEET_AI_REVIEWED_STATUS:
      return {
        ...state,
        timesheets: state.timesheets.map((timesheet) =>
          timesheet.file.preview === action.payload.id
            ? { ...timesheet, aIReviewStatus: action.payload.status }
            : timesheet
        ),
      };
    case UPDATE_TIMESHEET_AI_REVIEWED_STATUS_CANDY:
      return {
        ...state,
        timesheets: state.timesheets?.map((timesheet) =>
          timesheet.DBData?.referenceNo === action.payload.id
            ? { ...timesheet, aIReviewStatus: action.payload.status }
            : timesheet
        ),
      };
    case FINAL_MANUALLY_REVIEW_CONFIRMATION:
      return {
        ...state,
        finalManualReviewConfirmation: action.payload,
      };

    case ADD_AI_TIMESHEET_DATA:
      const { AIModalData } = action.payload;
      return {
        ...state,
        timesheets: state.timesheets.map((timesheet) =>
          timesheet.file.preview === action.payload.id
            ? { ...timesheet, AIModalData }
            : timesheet
        ),
      };
    case ADD_SYSTEM_TIMESHEET_DATA:
      const { DBData } = action.payload;
      return {
        ...state,
        timesheets: state.timesheets.map((timesheet) =>
          timesheet.file.preview === action.payload.id
            ? { ...timesheet, DBData }
            : timesheet
        ),
      };
    case UPDATE_TIMESHEET_SCAN_PROCESSING_STATUS:
      return {
        ...state,
        scanProcessingStatus: action.payload,
      };

    // case UPDATE_TIMESHEET_S3BUCKETFILEURL:
    // return {
    //   ...state,
    //   s3bucketfileurl: action.payload,
    // };

    // case LOG_FAILED_TIMESHEETS:
    //   return {
    //     ...state,
    //     failedTimesheets: [
    //       ...state?.failedTimesheets,
    //       {
    //         file: action.payload.file,
    //         errorMessage: action.payload.errorMessage,
    //       },
    //     ],
    //   };
    case LOG_FAILED_TIMESHEETS:
      return {
        ...state,
        failedTimesheets: state.failedTimesheets.some(
          (item) => item.file.preview === action.payload.file.preview
        )
          ? state.failedTimesheets
          : [
              ...state?.failedTimesheets,
              {
                file: action.payload.file,
                errorMessage: action.payload.errorMessage,
              },
            ],
      };

    // reducer.js

    case SAVE_GENERATED_URL:
      return {
        ...state,
        generatedUrl: action.payload,
      };
    case SCAN_STATUS_INDIVIDUAL:
      return {
        ...state,
        timesheets: state.timesheets.map((timesheet) =>
          timesheet.file.preview === action.payload.id
            ? {
                ...timesheet,
                scanStatus: {
                  status: action.payload.status,
                  message: action.payload.message,
                },
              }
            : timesheet
        ),
      };
    case PRE_VERIFIED_STATUS:
      return {
        ...state,
        timesheets: state.timesheets.map((timesheet) =>
          timesheet.file.preview === action.payload.id
            ? {
                ...timesheet,
                preVerifiedAndProccessedStatus: {
                  status: action.payload.status,
                  message: action.payload.message,
                },
              }
            : timesheet
        ),
      };

    case GET_LOCAL_TIMESHEETS:
      return {
        ...state,
        timesheets: action.payload,
      };

    default:
      return state;
  }
}
