export const REGISTER_USER = "REGISTER_USER";
export const LOGIN_USER = "LOGIN_USER";
export const SET_AUTHENTICATION = "SET_AUTHENTICATION";
export const AUTHENTICATED = "AUTHENTICATED";
export const UNAUTHENTICATED = "UNAUTHENTICATED";
export const INVALID_USER_CREDENTIALS = "INVALID_USER_CREDENTIALS";
export const SYSTEM_ERROR = "SYSTEM_ERROR";
export const SET_LOGGING_PROGRESS = "CHANGE_LOGGING_PROGRESS";

// Headhunter action types
export const REGISTER_NEW_CANDIDATE = "REGISTER_NEW_CANDIDATE";
export const REGISTER_NEW_CANDIDATE_INPROGRESS = "REGISTER_NEW_CANDIDATE";
export const CANDIDATE_EXISTS = "CANDIDATE_EXISTS";
export const CANDIDATE_REGISTRATION_SUCCESS = "CANDIDATE_REGISTRATION_SUCCESS";
export const FETCH_TODAY_HEADHUNTER_CANDIDATES =
  "FETCH_TODAY_HEADHUNTER_CANDIDATES";
export const FETCH_TODAY_HEADHUNTER_CANDIDATES_INPROGRESS =
  "FETCH_TODAY_HEADHUNTER_CANDIDATES_INPROGRESS";

//Initial data action type
export const GET_EMPLOYEES = "GET_EMPLOYEES";
export const GET_CLIENTS = "GET_CLIENTS";
export const GET_DEPARTMENTS = "GET_DEPARTMENTS";
export const GET_SPECIALITIES = "GET_SPECIALITIES";
export const GET_ROLES = "GET_ROLES";
export const GET_BANDS = "GET_BANDS";
export const GET_GRADES = "GET_GRADES";
export const GET_COUNTIES = "GET_COUNTIES";
export const GET_ADDRESS_WARDS = "GET_ADDRESS_WARDS";
export const GET_DISTRICTS = "GET_DISTRICTS";
export const GET_CV_SOURCES = "GET_CV_SOURCES";

//timesheet AI/System data action type
export const ADD_AI_TIMESHEET_DATA = "ADD_AI_TIMESHEET_DATA";
export const UPDATE_AI_TIMESHEET_DATA = "UPDATE_AI_TIMESHEET_DATA";
export const DELETE_AI_TIMESHEET_DATA = " DELETE_AI_TIMESHEET_DATA";
export const GET_AI_TIMESHEET_DATA = "GET_AI_TIMESHEET_DATA";

export const ADD_SYSTEM_TIMESHEET_DATA = "ADD_SYSTEM_TIMESHEET_DATA";
export const UPDATE_SYSTEM_TIMESHEET_DATA = "UPDATE_SYSTEM_TIMESHEET_DATA";
export const DELETE_SYSTEM_TIMESHEET_DATA = " DELETE_SYSTEM_TIMESHEET_DATA";
export const GET_SYSTEM_TIMESHEET_DATA = "GET_SYSTEM_TIMESHEET_DATA";

//Timesheets save locally
export const SAVE_TIMESHEETS_LOCALLY = "SAVE_TIMESHEETS_LOCALLY";
export const REMOVE_TIMESHEETS_LOCALLY_ALL = "REMOVE_TIMESHEETS_LOCALLY_ALL";
export const REMOVE_TIMESHEETS_LOCALLY_ONE = "REMOVE_TIMESHEETS_LOCALLY_ONE";
export const REMOVE_TIMESHEETS_LOCALLY_ONE_BEFORE_PROCESS = "REMOVE_TIMESHEETS_LOCALLY_ONE_BEFORE_PROCESS";


export const GET_LOCAL_TIMESHEETS = "GET_LOCAL_TIMESHEETS";
export const TIME_SHEET_EXIST_OR_NOT = "TIME_SHEET_EXIST_OR_NOT";
export const UPDATE_TIMESHEET_REVIEWED_DATA = "UPDATE_TIMESHEET_REVIEWED_DATA";
export const UPDATE_TIMESHEET_SCAN_PROCESSING_STATUS =
  "UPDATE_TIMESHEET_SCAN_PROCESSING_STATUS";
export const LOG_FAILED_TIMESHEETS = "LOG_FAILED_TIMESHEETS";
export const SCAN_STATUS_INDIVIDUAL = "SCAN_STATUS_INDIVIDUAL";
export const UPDATE_TIMESHEET_AI_REVIEWED_STATUS =
  "UPDATE_TIMESHEET_AI_REVIEWED_STATUS";
export const UPDATE_TIMESHEET_AI_REVIEWED_STATUS_CANDY =
  "UPDATE_TIMESHEET_AI_REVIEWED_STATUS_CANDY";
export const FINAL_MANUALLY_REVIEW_CONFIRMATION =
  "FINAL_MANUALLY_REVIEW_CONFIRMATION";
export const UPDATE_TIMESHEET_S3BUCKETFILEURL =
  "UPDATE_TIMESHEET_S3BUCKETFILEURL";
export const UPDATE_TIMESHEET_NAME_AND_EXTENTION =
  "UPDATE_TIMESHEET_NAME_AND_EXTENTION";
export const UPDATE_TIMESHEET_URL = "UPDATE_TIMESHEET_URL";
export const PRE_VERIFIED_STATUS = "PRE_VERIFIED_STATUS";

export const UPDATE_TIMESHEET_REVIEWED_DATA_CANDY =
  "UPDATE_TIMESHEET_REVIEWED_DATA_CANDY";

//Mobile Timesheets save locally

export const SAVE_MOBILE_TIMESHEETS_LOCALLY = "SAVE_MOBILE_TIMESHEETS_LOCALLY";
export const ADD_MOBILE_AI_TIMESHEET_DATA = "ADD_MOBILE_AI_TIMESHEET_DATA";
export const UPDATE_MOBILE_AI_TIMESHEET_DATA =
  "UPDATE_MOBILE_AI_TIMESHEET_DATA";
export const DELETE_MOBILE_AI_TIMESHEET_DATA =
  " DELETE_MOBILE_AI_TIMESHEET_DATA";
export const GET_MOBILE_AI_TIMESHEET_DATA = "GET_MOBILE_AI_TIMESHEET_DATA";

export const MOBILE_TIME_SHEET_EXIST_OR_NOT = "MOBILE_TIME_SHEET_EXIST_OR_NOT";
export const UPDATE_MOBILE_TIMESHEET_REVIEWED_DATA =
  "UPDATE_MOBILE_TIMESHEET_REVIEWED_DATA";
export const UPDATE_MOBILE_TIMESHEET_AI_REVIEWED_STATUS =
  "UPDATE_MOBILE_TIMESHEET_AI_REVIEWED_STATUS";

export const ADD_MOBILE_SYSTEM_TIMESHEET_DATA =
  "ADD_MOBILE_SYSTEM_TIMESHEET_DATA";
export const SCAN_MOBILE_STATUS_INDIVIDUAL = "SCAN_MOBILE_STATUS_INDIVIDUAL";
export const PRE_MOBILE_VERIFIED_STATUS = "PRE_MOBILE_VERIFIED_STATUS";

//email server action types
export const EMAIL_SERVER_CREDENTIALS = "EMAIL_SERVER_CREDENTIALS";
export const EMAIL_BODY_OTHER_VALUES = "EMAIL_BODY_OTHER_VALUES";
export const EMAIL_BODY_POSTS = "EMAIL_BODY_POSTS";

// Settings action types
export const SWITCH_SIDE_NAVIGATION = "SWITCH_SIDE_NAVIGATION";
export const SWITCH_DASHBOARD = "SWITCH_DASHBOARD";
export const UPDATING_WINDOW_SIZE = "UPDATING_WINDOW_SIZE";
export const UPDATING_WINDOW_HEIGHT = "UPDATING_WINDOW_HEIGHT";

//color mode action type
export const CHANGE_THEME = "CHANGE_THEME";

//chat notification
export const NEW_CHAT_RECEIVED = "NEW_CHAT_RECEIVED";
//saveFiledData
export const SAVE_FILED_DATA = "SAVE_FILED_DATA";

//notifications
export const TODO_NOTIFICATION = "TODO_NOTIFICATION";
export const RESCHEDULE_NOTIFICATION = "RESCHEDULE_NOTIFICATION";
export const EVENT_NOTIFICATION = "EVENT_NOTIFICATION";
export const TRAINING_NOTIFICATION = "TRAINING_NOTIFICATION";
export const EMAIL_NOTIFICATION = "EMAIL_NOTIFICATION";
export const UNCONFIRMED_BOOKING_NOTIFICATION =
  "UNCONFIRMED_BOOKING_NOTIFICATION";
export const CLIENT_CREATION_NOTIFICATION = "CLIENT_CREATION_NOTIFICATION";
export const REFERENCE_NOTIFICATION = "REFERENCE_NOTIFICATION";

//chat db type
export const CHAT_DB_POST = "CHAT_DB_POST";
export const CHAT_DB_GET = "CHAT_DB_GET";
export const CHAT_ARRAY = "CHAT_ARRAY";
export const CHAT_TAB = "CHAT_TAB";

//headhunter filtered workspace data
export const ADD_FILTERED_HEADHUNTER_WORKSPACE =
  "ADD_FILTERED_HEADHUNTER_WORKSPACE";

//Twilio
export const SETUP_TWILIO = "SETUP_TWILIO";
export const MAKE_A_CALL = "MAKE_A_CALL";
export const INCOMING_CALL = "INCOMING_CALL";

//Softphone Integration
export const STORE_SOFTPHONE_WEBSOCKET_DATA = "STORE_SOFTPHONE_WEBSOCKET_DATA";

export const SAVE_GENERATED_URL = "SAVE_GENERATED_URL";
