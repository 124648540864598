import React, { useState, useEffect } from "react";
import axios from "axios";
import { forEach, filter, isEmpty, isNull } from "lodash";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import ToastCommon from "../../toastCommon";
import {
  HOSTMexxar,
  HostMexxarWS,
  REGISTER_CANDIDATE,
} from "../../../configs/api-config";
import {
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Alert,
  Input,
  Spinner,
} from "reactstrap";
import notNull from "../../../util/common/notNull";
import DateInput from "../../../util/common/DateInput";
import moment from "moment";
import dateFormatter from "../../../util/common/dateFormatter";
import { sendNotification } from "../../../util/common/sendNotification";
import { connect } from "react-redux";
import { Client as StompClient } from "@stomp/stompjs";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { sendNotificationWeb } from "../../../util/common/sendNotificationWeb";

function ReferenceFormTemplate(props) {
  const {
    candidateId,
    refId,
    closeModal,
    rows,
    page,
    type,
    user,
    identifier,
    getData,
    auth,
  } = props;
  const MexxarApi = REGISTER_CANDIDATE;

  const { addToast } = useToasts();
  const { register, handleSubmit, errors } = useForm();
  // const [rows, setRows] = useState(null);
  const [getLoading, setGetLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [identifierOneAvailable, setIdentifierOneAvailable] = useState(false);
  const [identifierTwoAvailable, setIdentifierTwoAvailable] = useState(false);
  const [error, setError] = useState(null);
  const [sendEmail, setSendEmail] = useState(false);
  const [referenceFormStatus, setReferenceFormStatus] = useState(null);
  const history = useHistory();

  //common filter to extract data wth key and values //
  const filterData = (data, identifier) => {
    let data_List = [];

    let id = data[identifier + "ID" + refId];
    let value = data[identifier + refId];

    data_List.push({
      id: Number(id),
      identifier: identifier + refId,
      value: value,
      detailCategory: 7,
    });

    return data_List;
  };

  const getReferenceForm = () => {
    axios
      .get(`${HOSTMexxar}reference-form/candidate/${candidateId}`)
      .then((res) => {
        if (res.data.status == "success") {
          if (res.data.body.length > 0) {
            let rFData = filter(res.data.body, function (item) {
              return Number(item.identifier) == Number(identifier);
            });
            // setReferenceForm(rFData[0]);
            setReferenceFormStatus(rFData[0]?.referenceStatus);
            // getData();
          } else {
            // setReferenceForm(null);
          }
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };

  useEffect(() => {
    getReferenceForm();
    getData();
    return () => {};
  }, []);

  const onSubmit = (data) => {
    setPostLoading(true);

    let AdvanceDetailsArray = [];

    const formattedData = {
      ...data,
      [`rDateOfEmployement${refId}`]: data[`rDateOfEmployement${refId}`],
      [`rDateOfTermination${refId}`]: data[`rDateOfTermination${refId}`],
    };

    //check if this is update-form-filling or new-form-filling
    if (rows && notNull(rows[0])) {
      let A = filterData(formattedData, "rJobTitle");
      let B = filterData(formattedData, "rBand");
      let C = filterData(formattedData, "rDateOfEmployement");
      let D = filterData(formattedData, "rDateOfTermination");
      let E = filterData(formattedData, "rOrganisationName");
      let F = filterData(formattedData, "rTelephoneNumber");
      let G = filterData(formattedData, "rEmail");
      let H = filterData(formattedData, "rAddress");
      let I = filterData(formattedData, "refereeName");
      let J = filterData(formattedData, "rPostcode");

      let updatingArray = A.concat(B, C, D, E, F, G, H, I, J);
      AdvanceDetailsArray = updatingArray;
    } else {
      forEach(data, function (value, key) {
        if (value === "") {
        } else {
          AdvanceDetailsArray.push({
            identifier: key,
            value: value,
            detailCategory: 7,
          });
        }
      });
    }

    axios
      .post(
        MexxarApi + "/" + candidateId + "/advance-profile-details",
        AdvanceDetailsArray
      )
      .then((res) => {
        if (res.data.status === "success") {
          // getAllRows();
          // setShowAddRow(!showAddRow);

          sendNotification({
            notificationType: "NOTICE",
            title: "Reference form",
            content: `Hi,${type?.candidateFirstName} ${type?.candidateLastName}'s reference form is sent`,
            candidateId: auth?.item?.id,
            startDate: moment().toISOString(),
            addToast,
          });

          sendNotification({
            notificationType: "NOTICE",
            title: "Reference form",
            content: `Hi, ${type?.candidateFirstName} ${type?.candidateLastName}'s reference form is sent`,
            candidateId: candidateId,
            startDate: moment().toISOString(),
            addToast,
          });

          sendNotificationWeb({
            action: "Create",
            tittle: "Reference form",
            body: `Hi,${type?.candidateFirstName} ${type?.candidateLastName}'s reference form is sent`,
            userId: candidateId,
            date: moment().toISOString(),
          });
          sendNotificationWeb({
            action: "Create",
            tittle: "Reference form",
            body: `Hi, ${type?.candidateFirstName} ${type?.candidateLastName}'s reference form is sent`,
            userId: auth?.item?.id,
            date: moment().toISOString(),
          });

          if (sendEmail) {
            sendRef();
          }
        } else if (res.data.status === "failed") {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        //console.log(err);
        ToastCommon(true, "error", addToast);
      });
    setPostLoading(false);
  };

  const sendRef = () => {
    setPostLoading(true);
    setSendEmail(false);
    axios({
      method: "post",
      url:
        HOSTMexxar +
        "candidates/" +
        candidateId +
        "/send-reference-email?identifier=" +
        refId,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
      // withCredentials: true,
      mode: "cors",
    })
      .then((res) => {
        if (res.data.status == "success") {
          ToastCommon(true, "send", addToast);
          // closeModal()
          // getData();
        } else if (res.data.status == "failed") {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
    setPostLoading(false);
  };

  useEffect(() => {
    // getData();
    return () => {};
  }, []);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div>
          {referenceFormStatus === "RESUBMIT" && (
            <Alert
              className="d-flex align-items-justify  justify-content-justify flex-column"
              color="warning"
            >
              <p>
                Form has been resubmitted and awaiting user response. You may
                update any of the below fields and resend before the user
                responds.
              </p>
            </Alert>
          )}
          <div className="">
            <div className="card-header pl-0 ml-0 col-md-12 d-flex justify-content-between align-items-center">
              <h5>Reference {refId + 1}</h5>
            </div>
          </div>

          <div className="mt-2">
            <FormGroup row>
              <Col sm={12} md={4}>
                <Label>
                  Job Title <span className="text-danger">*</span>
                </Label>

                <Input
                  type="name"
                  name={"rJobTitle" + refId}
                  placeholder="Job Title "
                  defaultValue={rows && rows[0]?.rJobTitle}
                  innerRef={register({
                    required: {
                      value: true,
                      message: "This field is required!",
                    },
                  })}
                />
                <Input
                  type="hidden"
                  innerRef={register}
                  name={"rJobTitleID" + refId}
                  defaultValue={rows && rows[0]?.rJobTitleID}
                />
              </Col>
              <Col sm={12} md={4}>
                <Label>Band</Label>
                <Input
                  type="text"
                  name={"rBand" + refId}
                  placeholder="Band"
                  defaultValue={rows && rows[0]?.rBand}
                  innerRef={register({
                    required: {
                      value: false,
                      message: "This field is required!",
                    },
                  })}
                />
                <Input
                  type="hidden"
                  innerRef={register}
                  name={"rBandID" + refId}
                  defaultValue={rows && rows[0]?.rBandID}
                />
              </Col>
              <Col sm={12} md={4}>
                <Label>Organisation Name</Label>
                <Input
                  type="name"
                  name={"rOrganisationName" + refId}
                  placeholder="Organisation Name"
                  defaultValue={rows && rows[0]?.rOrganisationName}
                  innerRef={register({
                    required: {
                      value: false,
                      message: "This field is required!",
                    },
                  })}
                />
                <Input
                  type="hidden"
                  innerRef={register}
                  name={"rOrganisationNameID" + refId}
                  defaultValue={rows && rows[0]?.rOrganisationNameID}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm={12} md={6}>
                <Label>Date of Employment</Label>
                <DateInput
                  id={"rDateOfEmployement" + refId}
                  value={rows && rows[0]?.rDateOfEmployement}
                  register={register}
                />

                <Input
                  type="hidden"
                  innerRef={register}
                  name={"rDateOfEmployementID" + refId}
                  defaultValue={rows && rows[0]?.rDateOfEmployementID}
                />
              </Col>
              <Col sm={12} md={6}>
                <Label>Date of Termination</Label>
                <DateInput
                  id={"rDateOfTermination" + refId}
                  value={rows && rows[0]?.rDateOfTermination}
                  register={register}
                />

                <Input
                  type="hidden"
                  innerRef={register}
                  name={"rDateOfTerminationID" + refId}
                  defaultValue={rows && rows[0]?.rDateOfTerminationID}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm={12} md={4}>
                <Label>
                  Referee Name <span className="text-danger">*</span>
                </Label>
                <Input
                  type="name"
                  name={"refereeName" + refId}
                  placeholder="Referee's Name"
                  defaultValue={rows && rows[0]?.refereeName}
                  innerRef={register({
                    required: {
                      value: true,
                      message: "This field is required!",
                    },
                  })}
                />
                <Input
                  type="hidden"
                  innerRef={register}
                  name={"refereeNameID" + refId}
                  defaultValue={rows && rows[0]?.refereeNameID}
                />
              </Col>
              <Col sm={12} md={4}>
                <Label>Telephone Number</Label>
                <Input
                  type="text"
                  name={"rTelephoneNumber" + refId}
                  placeholder="Telephone Number"
                  defaultValue={rows && rows[0]?.rTelephoneNumber}
                  innerRef={register({
                    required: {
                      value: false,
                      message: "This field is required!",
                    },
                    pattern: {
                      value:
                        /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/,
                      message: "Please enter a valid phone number",
                    },
                  })}
                />
                <Input
                  type="hidden"
                  innerRef={register}
                  name={"rTelephoneNumberID" + refId}
                  defaultValue={rows && rows[0]?.rTelephoneNumberID}
                />
              </Col>
              <Col sm={12} md={4}>
                <Label>
                  Referee Email <span className="text-danger">*</span>
                </Label>

                <Input
                  type="rEmail"
                  name={"rEmail" + refId}
                  placeholder="Refree Email"
                  defaultValue={rows && rows[0]?.rEmail}
                  innerRef={register({
                    required: {
                      value: true,
                      message: "This field is required!",
                    },
                    pattern: {
                      value:
                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Please enter a valid email address!",
                    },
                  })}
                />
                <Input
                  type="hidden"
                  innerRef={register}
                  name={"rEmailID" + refId}
                  defaultValue={rows && rows[0]?.rEmailID}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm={12} md={8}>
                <Label>Address</Label>
                <Input
                  type="text"
                  name={"rAddress" + refId}
                  placeholder="Refree Address"
                  defaultValue={rows && rows[0]?.rAddress}
                  innerRef={register({
                    required: {
                      value: false,
                      message: "This field is required!",
                    },
                  })}
                />
                <Input
                  type="hidden"
                  innerRef={register}
                  name={"rAddressID" + refId}
                  defaultValue={rows && rows[0]?.rAddressID}
                />
              </Col>
              <Col sm={12} md={4}>
                <Label>
                  Post Code <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  name={"rPostcode" + refId}
                  placeholder="Postal code"
                  defaultValue={rows && rows[0]?.rPostcode}
                  innerRef={register({
                    required: {
                      value: true,
                      message: "This field is required!",
                    },
                  })}
                />
                <Input
                  type="hidden"
                  innerRef={register}
                  name={"rPostcodeID" + refId}
                  defaultValue={rows && rows[0]?.rPostcodeID}
                />
              </Col>
            </FormGroup>
          </div>
        </div>

        <div className="d-flex justify-content-between">
          <div></div>
          <div>
            <Button
              className="mr-2"
              color="success"
              type="submit"
              onClick={() => setSendEmail(true)}
            >
              {postLoading && (
                <Spinner
                  animation="border"
                  style={{
                    height: "20px",
                    width: "20px",
                    marginRight: "5px",
                  }}
                />
              )}
              {notNull(rows) && notNull(rows[0])
                ? "Update & Send Email"
                : "Save & Send Email"}
            </Button>
            <Button className="mr-2" color="success" type="submit">
              {postLoading && (
                <Spinner
                  animation="border"
                  style={{
                    height: "20px",
                    width: "20px",
                    marginRight: "5px",
                  }}
                />
              )}
              {notNull(rows) && notNull(rows[0]) ? "Update" : "Save"}
            </Button>
            {page == "referenceWorkspace" && (
              <Button color="danger" onClick={() => closeModal()}>
                Close
              </Button>
            )}
          </div>
        </div>
      </Form>
    </>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(ReferenceFormTemplate);
