import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Button,
  Col,
  UncontrolledTooltip,
  Input,
  Label,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Collapse,
  Card,
  CardBody,
  Row,
} from "reactstrap";
import axios from "axios";
import moment from "moment";
import { ClientLable, HOSTMexxar } from "../../configs/api-config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import NoSearchResults from "../../pages/errorPages/NoSearchResults";
import { useSpring, animated } from "react-spring";
import { FunnelIcon, ReUploadIcon } from "../../assets/icons/svg";
import { Link } from "react-router-dom";
import loadingAnimation from "../../../src/images/puff.svg";
import ModalComponent from "../../components/Modal/modalComponent";
import TimeSheetUpload from "../../components/Modal/ModalBody/timeSheetUpload";
import usePayroll from "../../customHooks/usePayroll";
import DateInput from "../../util/common/DateInput";
import dateFormatter from "../../util/common/dateFormatter";
import NoConnection from "../../pages/errorPages/NoConnection";

const EmployeePayrollWidgetRejected = () => {
  const currentDate = new Date();

  const defaultStartDay = dateFormatter.formatDateToYYYYMMDDSlash(
    moment(currentDate).subtract(30, "days")
  );
  const tommorow = dateFormatter.formatDateToYYYYMMDDSlash(
    moment(currentDate).add(1, "days")
  );

  const [searchQuery, setSearchQuery] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [showTimeModal, setShowTimeModal] = useState(false);
  const [data, setData] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [resetResults, setResetResults] = useState(false);
  const [searchAdvanced, setSearchAdvanced] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [getEndDate, setGetEndDate] = useState(tommorow);
  const [getStartDate, setGetStartDate] = useState(defaultStartDay);

  const MexxarAPI = HOSTMexxar;

  let url = MexxarAPI + "workspace/payroll?rs=DECLINE&candidateStatus=ACTIVE";

  const animationProps = useSpring({
    opacity: 1,
    transform: "translate(0px, 0px)",
    from: { opacity: 0, transform: "translate(-100px, 0px)" },
  });

  const { loading, error, rows, hasMore, totalElements } = usePayroll(
    url,
    searchQuery,
    pageNumber,
    searchAdvanced,
    moment(getStartDate).format("YYYY/MM/DD"),
    moment(getEndDate).format("YYYY/MM/DD"),
    resetResults
  );

  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );
  const reset = () => {
    setPageNumber(1);
    setResetResults(!resetResults);
    setGetStartDate(defaultStartDay);
    setGetEndDate(tommorow);
  };
  const handleSearchOnChange = (e) => {
    setPageNumber(1);
    setSearchQuery(e.target.value);
  };
  const toggleModal = (reason = "") => {
    if (!modalOpen) {
      setRejectReason(reason);
    }
    setModalOpen(!modalOpen);
  };

  const toggleShowTimeModal = (data) => {
    setData(data.booking);
    setShowTimeModal(!showTimeModal);
  };

  useEffect(() => {
    setPageNumber(1);
    setResetResults(!resetResults);
  }, [showTimeModal]);

  return (
    <div>
      <div className="form-inline mb-2">
        <FontAwesomeIcon icon={faFileAlt} className={"ml-2 mr-3"} />
        <Label className={"mr-3"}>{totalElements} Timesheets</Label>
        <Link to="/payroll/mobileTimesheetScan"></Link>
        <Button
          onClick={() => setIsOpenFilter(!isOpenFilter)}
          className="btn bg-dark-lt text-dark"
        >
          <FunnelIcon />
          <span className="mx-1">Filter</span>
        </Button>

        <Input
          type="text"
          id="searchBar"
          className="form-control no-border no-shadow no-bg typeahead tt-input"
          placeholder="Search Candidates..."
          autoComplete="off"
          spellCheck="false"
          dir="auto"
          style={{ backgroundColor: "transparent" }}
          onChange={handleSearchOnChange}
        />
      </div>

      <UncontrolledTooltip target="searchBar">
        Search candidates by Name/Id/Client Id/ Name
      </UncontrolledTooltip>

      <Collapse isOpen={isOpenFilter}>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <Label for="exampleDate">Start Date</Label>

                <DateInput
                  id="startDate"
                  value={dateFormatter.formatDateToDDMMYYYY(getStartDate)}
                  onChange={(date) =>
                    setGetStartDate(dateFormatter.formatDateToYYYYMMDD(date))
                  }
                />
              </Col>
              <Col>
                <Label for="exampleDate">End Date</Label>

                <DateInput
                  id="endDate"
                  value={dateFormatter.formatDateToDDMMYYYY(getEndDate)}
                  onChange={(date) =>
                    setGetEndDate(dateFormatter.formatDateToYYYYMMDD(date))
                  }
                />
              </Col>

              <Col>
                <div className="d-flex flex-column align-items-end">
                  <Button
                    color="success m-1 btn-sm"
                    style={{ width: "70px" }}
                    // onClick={handleSearch}
                    onClick={() => {
                      setPageNumber(1);
                      setSearchAdvanced(!searchAdvanced);
                    }}
                  >
                    Search
                  </Button>
                  <Button
                    color="danger m-1 btn-sm"
                    onClick={reset}
                    style={{ width: "70px" }}
                  >
                    Reset
                  </Button>
                </div>
              </Col>
            </Row>
            <Row></Row>
          </CardBody>
        </Card>
      </Collapse>
      {error && (
        <div>
          <NoConnection error={error}></NoConnection>
        </div>
      )}

      {rows.map((row, index) => {
        if (rows.length === index + 1) {
          return (
            <div ref={lastElementRef} key={row.id}>
              <animated.div style={animationProps} key={row.id}>
                <div className="card mb-2" key={row.id}>
                  <div className="card-body">
                    <div className="flex flex-row d-flex justify-content-between">
                      <div className="d-flex" style={{ width: "250px" }}>
                        <div className="d-flex align-items-center mr-3">
                          <span className="mx-2">
                            <b className="badge badge-circle sm text-primary"></b>
                          </span>
                        </div>
                        <div>
                          <div>{row.locumName}</div>
                          <div className="item-mail text-muted h-1x d-none d-sm-block">
                            <small>
                              Shift Date:{" "}
                              {moment(row.dateOfShift).format("llll")}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div className="textCenter-Custom">
                        <div>Booking Reference</div>
                        <div className="text-muted">{row.bookingRef}</div>
                      </div>
                      <div className="textCenter-Custom">
                        <div>Hospital Name</div>
                        <div className="text-muted">{row.hospitalName}</div>
                      </div>

                      <div>
                        <Button
                          color="success"
                          className="mr-2"
                          onClick={() => toggleModal(row.rejectReason)}
                        >
                          Reason
                        </Button>
                        <Button
                          color="primary"
                          className="mr-2"
                          onClick={() => {
                            toggleShowTimeModal(row);
                          }}
                        >
                          <ReUploadIcon />
                          <span className="mx-1">Reupload</span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </animated.div>
            </div>
          );
        } else {
          return (
            <div key={row.id}>
              <animated.div style={animationProps} key={row.id}>
                <div className="card mb-2" key={row.id}>
                  <div className="card-body">
                    <div className="flex flex-row d-flex justify-content-between">
                      <div className="d-flex" style={{ width: "250px" }}>
                        <div className="d-flex align-items-center mr-3">
                          <span className="mx-2">
                            <b className="badge badge-circle sm text-primary"></b>
                          </span>
                        </div>
                        <div>
                          <div>{row.locumName}</div>
                          <div className="item-mail text-muted h-1x d-none d-sm-block">
                            <small>
                              Shift Date:{" "}
                              {moment(row.dateOfShift).format("llll")}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div className="textCenter-Custom">
                        <div>Booking Reference</div>
                        <div className="text-muted">{row.bookingRef}</div>
                      </div>
                      <div className="textCenter-Custom">
                        <div>Hospital Name</div>
                        <div className="text-muted">{row.hospitalName}</div>
                      </div>

                      <div>
                        <Button
                          color="success"
                          className="mr-2"
                          onClick={() => toggleModal(row.rejectReason)}
                        >
                          Reason
                        </Button>
                        <Button
                          color="primary"
                          className="mr-2"
                          onClick={() => {
                            toggleShowTimeModal(row);
                          }}
                        >
                          <ReUploadIcon />
                          <span className="mx-1">Reupload</span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </animated.div>
            </div>
          );
        }
      })}

      {rows.length === 0 && !loading && <NoSearchResults />}

      {loading && !error && (
        <div className="d-flex justify-content-center align-items-center">
          <div>
            <img src={loadingAnimation} style={{ height: 100 }}></img>
          </div>
        </div>
      )}
      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Timesheet Reject Reason</ModalHeader>
        <ModalBody>
          <p>{rejectReason || "No reason provided."}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={toggleModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <ModalComponent
        show={showTimeModal}
        header="Upload Timesheet"
        size="lg"
        closeModal={() => setShowTimeModal(false)}
      >
        <TimeSheetUpload
          data={data}
          closeModal={() => setShowTimeModal(false)}
          // getCandidates={refreshPage}
        ></TimeSheetUpload>
      </ModalComponent>
    </div>
  );
};

export default EmployeePayrollWidgetRejected;
