import React from "react";
import axios from "axios";
import { HOSTMexxar, TRAININGS } from "../../configs/api-config";

export const sendNotificationWeb = (data) => {
  const { action, tittle, body, userId, date, addToast } = data;
  const MexxarApiTRAININGS = TRAININGS;

  sendRequest(
    {
      action: action,
      tittle: tittle,
      body: body,
      userId: userId,
      date: date,
    },
    addToast
  );
};

const sendRequest = (requestData, addToast) => {
  axios
    .post(HOSTMexxar + "notifications", requestData)
    .then((res) => {
      if (res.data.status === "success") {
      } else {
        console.error("Notification sending failed:", res.data);
      }
    })
    .catch((err) => {
      console.error("Error sending notification:", err);
    });
};
