import React, { useState, useEffect } from "react";
import { Col, Button, Form, FormGroup, Label, Alert, Input } from "reactstrap";
import axios from "axios";
import moment from "moment";
import { filter, find } from "lodash";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import ToastCommon from "../../components/toastCommon";
import {
  HOSTMexxar,
  CompanyName,
  CommonEmailReplySignatureEmail,
  HostMexxarWS,
} from "../../configs/api-config";
import { useHistory } from "react-router-dom";

import useDidMountEffect from "../../components/Modal/ModalBody/intialRunUseEffect";
import ModalComponent from "../../components/Modal/modalComponent";

import html2pdf from "html2pdf.js";
import Approve from "../../components/Modal/ModalBody/Admin/approve";
import ResubmitForm from "../../components/Modal/ModalBody/Admin/resubmit";
import RejectForm from "../../components/Modal/ModalBody/Admin/reject";
import dateFormatter from "../../util/common/dateFormatter";
import DateInput from "../../util/common/DateInput";
import { sendNotification } from "../../util/common/sendNotification";
import { Client as StompClient } from "@stomp/stompjs";
import { sendNotificationWeb } from "../../util/common/sendNotificationWeb";
import { connect } from "react-redux";

function ReferenceForm(props) {
  const {
    mode = "POST",
    candidateId,
    closeModal,
    identifier,
    getData,
    reset,
    userProfile,
    auth,
  } = props;
  const MexxarApiRoot = HOSTMexxar;
  var candidateBand;
  const userAgent = window.navigator.userAgent;

  const { addToast } = useToasts();
  const history = useHistory();

  const [ukey, setUkey] = useState("");
  const [signed, setSigned] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const [candidateName, setCandidateName] = useState("");
  const [referenceForm, setReferenceForm] = useState(null);
  const [referenceFormStatus, setReferenceFormStatus] = useState(null);
  const [employeementDateError, setEmployeementDateError] = useState(false);
  const [bandError, setBandError] = useState(false);
  const [signedError, setSignedError] = useState(false);

  const [dateOfEmployement, setDateOfEmployement] = useState("");
  const [terminationDate, setTerminationDate] = useState("");

  //modals show/hide
  const [showResubmitForm, setShowResubmitForm] = useState(false);
  const [showRejectForm, setShowRejectForm] = useState(false);
  const [showApprovedForm, setShowApprovedForm] = useState(false);

  useDidMountEffect(() => {
    if (moment(terminationDate).isSameOrBefore(moment(dateOfEmployement))) {
      setEmployeementDateError(true);
    } else {
      setEmployeementDateError(false);
    }
  }, [dateOfEmployement, terminationDate]);

  const [IsWorkEmail, setIsWorkEmail] = useState(true);

  const intialBands = [
    "Band 1",
    "Band 2",
    "Band 3",
    "Band 4",
    "Band 5",
    "Band 6",
    "Band 7",
    "Band 8",
    "Band 9",
  ];

  const [bands, setBands] = useState(null);

  const [selectedBand, setSelectedBand] = useState(-1);

  const questions = [
    {
      questionNo: "colleagueRelationships",
      question: "Colleague Relationships",
    },
    {
      questionNo: "patientRelationships",
      question: "Patient Relationships",
    },
    {
      questionNo: "attitude",
      question: "Attitude",
    },
    {
      questionNo: "reliability",
      question: "Reliability",
    },
    {
      questionNo: "clinicalCompetency",
      question: "Clinical Competency",
    },
    {
      questionNo: "flexibility",
      question: "Flexibility",
    },
  ];

  const onSubmit = (data) => {
    if (
      moment(data.dateOfTermination).isSameOrBefore(
        moment(data.dateOfEmployment)
      )
    ) {
      return;
    } else {
      setEmployeementDateError(false);
    }

    if (selectedBand === -1) {
      setBandError(true);
      return;
    } else {
      setBandError(false);
    }

    if (!signed) {
      setSignedError(true);
      return;
    } else {
      setSignedError(false);
    }

    data.senderBrowserData = userAgent;
    data.senderIp = null;
    data.workEmail = IsWorkEmail;
    data.stamped = false;

    axios
      .post(
        MexxarApiRoot + `reference-form/save-reference-details?ukey=${ukey}`,
        data
      )
      .then((res) => {
        if (res.data.status == "success") {
          ToastCommon(true, "success", addToast);

          sendNotification({
            notificationType: "NOTICE",
            title: "Reference form",
            content: `Hi,${candidateName}'s reference form is Submited`,
            candidateId: auth?.item?.id,
            startDate: moment().toISOString(),
            addToast,
          });

          sendNotification({
            notificationType: "NOTICE",
            title: "Reference form",
            content: `Hi, ${candidateName}'s reference form is Submited`,
            candidateId: candidateId,
            startDate: moment().toISOString(),
            addToast,
          });

          sendNotificationWeb({
            action: "Submited",
            tittle: "Reference form",
            body: `Hi, ${candidateName}'s reference form is Submited`,
            userId: candidateId,
            date: moment().toISOString(),
          });
          sendNotificationWeb({
            action: "Submited",
            tittle: "Reference form",
            body: `Hi, ${candidateName}'s reference form is Submited`,
            userId: auth?.item?.id,
            date: moment().toISOString(),
          });

          if (
            mode == "GET" &&
            candidateId != null &&
            candidateId != undefined
          ) {
            getData();
          }

          history.push(
            `/refreeFormSuccess/${data.referenceName}/${data.candidateName}`
          );
        } else if (res.data.status == "failed") {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };

  const getReferenceForm = () => {
    axios
      .get(`${HOSTMexxar}reference-form/candidate/${candidateId}`)
      .then((res) => {
        if (res.data.status == "success") {
          if (res.data.body.length > 0) {
            let rFData = filter(res.data.body, function (item) {
              return Number(item.identifier) == Number(identifier);
            });
            setReferenceForm(rFData[0]);
            setReferenceFormStatus(rFData[0]?.referenceStatus);
          } else {
            setReferenceForm(null);
          }
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };

  const generatePDF = () => {
    const htmlTemplate = `
    <div style="font-family: Arial, sans-serif; background-color: #f8f9fa; color: #333; margin: 0; padding: 0;">
    <div style="max-width: 600px; margin: 50px auto; padding: 20px;  border-radius: 8px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);">

      <h1 style="text-align: center; color: #17202A; margin-bottom: 20px;">Reference Form</h1>

      <div style="margin-bottom: 20px;">
        <h3 style="color: #273746; border-bottom: 1px solid #ccc; padding-bottom: 10px;">Candidate Employment Details</h3>
        <ul style="list-style-type: none; padding: 0;">
          <li><strong>Candidate Name:</strong> ${
            referenceForm.candidateName
          }</li>
          <li><strong>Employed at:</strong> ${referenceForm.employedAt}</li>
          <li><strong>Job Title:</strong> ${referenceForm.jobTittle}</li>
          <li><strong>Date of Employment:</strong> ${moment(
            referenceForm?.dateOfEmployment
          ).format("YYYY-MM-DD")}</li>
          <li><strong>Date of Termination:</strong> ${moment(
            referenceForm?.dateOfTermination
          ).format("YYYY-MM-DD")}</li>
          <li><strong>Reason for Leaving:</strong> ${
            referenceForm.reasonForLeaving
          }</li>
        </ul>
      </div>
      
      <div style="margin-bottom: 20px;">
        <h3 style="color: #273746; border-bottom: 1px solid #ccc; padding-bottom: 10px;">Candidate Assessment</h3>
        <ul style="list-style-type: none; padding: 0;">
          <li><strong>Colleague Relationships:</strong> ${
            referenceForm.colleagueRelationships
          }</li>
          <li><strong>Patient Relationships:</strong> ${
            referenceForm.patientRelationships
          }</li>
          <li><strong>Attitude:</strong> ${referenceForm.attitude}</li>
          <li><strong>Reliability:</strong> ${referenceForm.reliability}</li>
          <li><strong>Clinical Competency:</strong> ${
            referenceForm.clinicalCompetency
          }</li>
          <li><strong>Flexibility:</strong> ${referenceForm.flexibility}</li>
        </ul>
      </div>
  
      <div style="margin-bottom: 20px;">
        <h3 style="color: #273746; border-bottom: 1px solid #ccc; padding-bottom: 10px;">How Would You Rate This Candidate Overall?</h3>
        <p><strong>Rating:</strong> 
          <span style="color: #f8cb00; font-size: 24px;">&#9733;&#9733;&#9733;&#9733;&#9733;</span>
        </p>
        <p><strong>Would you re-employ this candidate?</strong> ${
          referenceForm.reEmployCandidate
        }</p>
      </div>
  
      <div>
        <h3 style="color: #273746; border-bottom: 1px solid #ccc; padding-bottom: 10px;">Your Signature</h3>
        <ul style="list-style-type: none; padding: 0;">
          <li><strong>Referee Name:</strong> ${referenceForm.referenceName}</li>
          <li><strong>Telephone Number:</strong> ${
            referenceForm.telephoneNo
          }</li>
          <li><strong>Referee Email:</strong> ${referenceForm.refereeEmail}</li>
          <li><strong>Is this a work email Address?</strong> ${
            referenceForm.workEmail
          }</li>
          <li><strong>Address:</strong> ${referenceForm.address}</li>
          <li><strong>Post Code:</strong> ${referenceForm.postCode}</li>
          <li><strong>Band:</strong> ${referenceForm.band}</li>
        </ul>
      </div>
  
    </div>
    <div style="text-align: center; margin-top: 20px;">
        <p>Powered by Mexxar</p>
      </div>
</div>
    `;

    html2pdf()
      .from(htmlTemplate)
      .toPdf()
      .get("pdf")
      .then(function (pdf) {
        pdf.save("reference_form.pdf");
      });
  };

  const ApproveForm = () => {
    axios
      .patch(`${HOSTMexxar}reference-form/approve/${referenceForm.id}`)
      .then((res) => {
        if (res.data.status == "success") {
          getReferenceForm();
          reset();

          sendNotification({
            notificationType: "NOTICE",
            title: "Reference form",
            content: `Hi,${candidateName}'s reference form is Approved`,
            candidateId: auth?.item?.id,
            startDate: moment().toISOString(),
            addToast,
          });

          sendNotification({
            notificationType: "NOTICE",
            title: "Reference form",
            content: `Hi, ${candidateName}'s reference form is Approved`,
            candidateId: candidateId,
            startDate: moment().toISOString(),
            addToast,
          });

          sendNotificationWeb({
            action: "Approved",
            tittle: "Reference form",
            body: `Hi, ${candidateName}'s reference form is Resubmited`,
            userId: candidateId,
            date: moment().toISOString(),
          });
          sendNotificationWeb({
            action: "Approved",
            tittle: "Reference form",
            body: `Hi, ${candidateName}'s reference form is Aprroved`,
            userId: auth?.item?.id,
            date: moment().toISOString(),
          });
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };

  const checkBoxStatus = (question, identifier) => {
    let checkStatus = find(referenceForm, function (value, key) {
      if (key == question && value == identifier) {
        return true;
      } else {
        return false;
      }
    });

    return checkStatus;
  };

  const downloadRefForm = () => {
    // Find the element to hide
    const elementToHide = document.getElementById("downloadButton");

    // Hide the element before printing (optional)
    if (elementToHide) {
      elementToHide.style.display = "none";
    }

    // Trigger the browser's print dialog
    window.print();

    // Show the element again after printing (optional)
    if (elementToHide) {
      elementToHide.style.display = "block";
    }
  };

  useEffect(() => {
    if (referenceForm?.band != null ?? undefined) {
      setSelectedBand(referenceForm?.band);
    }
  }, [bands, referenceForm]);

  useEffect(() => {
    if (mode == "GET" && candidateId != null && candidateId != undefined) {
      getReferenceForm();
    } else {
      let ukeyTemp = new URLSearchParams(props.location.search).get("ukey");
      setUkey(ukeyTemp);
    }
    return () => {};
  }, [referenceFormStatus]);

  useEffect(() => {
    if (mode == "GET" && candidateId != null && candidateId != undefined) {
      getReferenceForm();
    } else {
      let ukeyTemp = new URLSearchParams(props.location.search).get("ukey");
      setUkey(ukeyTemp);
    }
    return () => {};
  }, [candidateId]);

  useEffect(() => {
    if (mode == "POST") {
      let ukeyTemp = new URLSearchParams(props.location.search).get("ukey");
      setUkey(ukeyTemp);
      const candidateName =
        ukeyTemp.split("-")[1] + " " + ukeyTemp.split("-")[2];
      setCandidateName(candidateName);
      candidateBand = decodeURIComponent(ukeyTemp.split("-").pop());
      // console.log("angi use effect cndidate band---->", candidateBand);

      setBands(intialBands.slice(intialBands.indexOf(candidateBand) + 1));
    } else {
      setBands(intialBands);
    }
    return () => {};
  }, []);
  return (
    <div className={`row ${mode == "POST" && "mx-5"}`}>
      <div className={`col-md-12  ${mode == "POST" && "p-5"}`}>
        {mode == "POST" && (
          <div className={`${mode == "POST" && "px-3 mx-5"}`}>
            <div className="card-header d-flex justify-content-start text-align-center">
              <h2>REFERENCE REQUEST FORM</h2>
            </div>

            <div className="m-3">
              <div>
                <Alert className="text-justify">
                  The following person has applied to work for us at{" "}
                  <b>{CompanyName}</b>&nbsp; and you have been nominated by them
                  to be their referee. We will need the following information
                  from you in order to assess their suitability to work for us.
                  Please fill this information out as accurately as possible. We
                  would like to take this opportunity to thank you for your
                  assistance in this matter.
                </Alert>
                <Alert color="info">
                  <span className="text-danger mx-2">*</span>
                  Note : Please fill all the fields before submitting the form.
                </Alert>
              </div>
            </div>
          </div>
        )}
        <div className={`${mode == "POST" && "p-3 mx-5"}`}>
          <div className="">
            <Form id="refForm" onSubmit={handleSubmit(onSubmit)}>
              <>
                <div className="  col-md-12 d-flex justify-content-between align-items-center">
                  <h5>Candidate Employment Details</h5>
                </div>

                <div className="mt-2">
                  <FormGroup row className="m-3">
                    <Col sm={12} md={4}>
                      <Label>Candidate Name</Label>
                      <Input
                        type="name"
                        name={"candidateName"}
                        placeholder="John Smith"
                        defaultValue={
                          referenceForm
                            ? referenceForm?.candidateName
                            : candidateName
                        }
                        innerRef={register({
                          required: {
                            value: true,
                            message: "This field is required!",
                          },
                        })}
                      />
                    </Col>
                    <Col sm={12} md={4}>
                      <Label>Employed at</Label>
                      <Input
                        type="name"
                        name="employedAt"
                        invalid={errors.employedAt}
                        defaultValue={
                          referenceForm && referenceForm?.employedAt
                        }
                        innerRef={register({
                          required: {
                            value: true,
                            message: "This field is required!",
                          },
                          maxLength: {
                            value: 100,
                            message:
                              "This field only allowed only 100 characters",
                          },
                          pattern: {
                            value: "/^[a-zA-Z0-9/()]+$/",
                            message:
                              "This field only allows letters and numbers.",
                          },
                        })}
                      />
                      {errors.employedAt && (
                        <ul
                          className="list-unstyled text-sm mt-1 text-muted filled"
                          id="parsley-id-119"
                        >
                          <li className="parsley-required text-danger">
                            {errors.employedAt?.message}
                          </li>
                        </ul>
                      )}
                    </Col>
                    <Col sm={12} md={4}>
                      <Label>Job Title</Label>
                      <Input
                        type="name"
                        name="jobTittle"
                        placeholder="A&E Nurse"
                        invalid={errors.jobTittle}
                        defaultValue={referenceForm && referenceForm?.jobTittle}
                        innerRef={register({
                          required: {
                            value: true,
                            message: "This field is required!",
                          },
                          maxLength: {
                            value: 100,
                            message:
                              "This field only allowed only 100 characters",
                          },
                          pattern: {
                            value: "/^[a-zA-Z0-9/()]+$/",
                            message:
                              "This field only allows letters and numbers.",
                          },
                        })}
                      />
                      {errors.jobTittle && (
                        <ul
                          className="list-unstyled text-sm mt-1 text-muted filled"
                          id="parsley-id-119"
                        >
                          <li className="parsley-required text-danger">
                            {errors.jobTittle?.message}
                          </li>
                        </ul>
                      )}
                    </Col>
                  </FormGroup>
                  <FormGroup row className="m-3">
                    <Col sm={12} md={6}>
                      <Label>Date of Employment</Label>

                      <DateInput
                        id="dateOfEmployment"
                        register={register({
                          required: {
                            value: true,
                            message: "This field is required!",
                          },
                        })}
                        onChange={(e) =>
                          setDateOfEmployement(
                            dateFormatter.formatDateToYYYYMMDD(e)
                          )
                        }
                        value={
                          referenceForm &&
                          dateFormatter.formatDateToDDMMYYYY(
                            moment(referenceForm?.dateOfEmployment).format(
                              "YYYY-MM-DD"
                            )
                          )
                        }
                      ></DateInput>
                      {errors.dateOfEmployment && (
                        <ul
                          className="list-unstyled text-sm mt-1 text-muted filled"
                          id="parsley-id-119"
                        >
                          <li className="parsley-required text-danger">
                            {errors.dateOfEmployment?.message}
                          </li>
                        </ul>
                      )}
                    </Col>
                    <Col sm={12} md={6}>
                      <Label>Date of Termination</Label>

                      <DateInput
                        id="dateOfTermination"
                        register={register({
                          required: {
                            value: true,
                            message: "This field is required!",
                          },
                        })}
                        onChange={(e) =>
                          setTerminationDate(
                            dateFormatter.formatDateToYYYYMMDD(e)
                          )
                        }
                        value={
                          referenceForm &&
                          dateFormatter.formatDateToDDMMYYYY(
                            moment(referenceForm?.dateOfTermination).format(
                              "YYYY-MM-DD"
                            )
                          )
                        }
                      ></DateInput>
                      {(errors.dateOfTermination || employeementDateError) && (
                        <ul
                          className="list-unstyled text-sm mt-1 text-muted filled"
                          id="parsley-id-119"
                        >
                          <li className="parsley-required text-danger">
                            {errors.dateOfTermination?.message}
                          </li>
                        </ul>
                      )}
                    </Col>
                  </FormGroup>
                  <FormGroup row className="m-3">
                    <Col sm={12} md={12}>
                      <Label>Reason for Leaving</Label>
                      <Input
                        type="name"
                        name="reasonForLeaving"
                        invalid={errors.reasonForLeaving}
                        placeholder="Reason For Leaving"
                        defaultValue={
                          referenceForm && referenceForm?.reasonForLeaving
                        }
                        innerRef={register({
                          required: {
                            value: true,
                            message: "This field is required!",
                          },
                          maxLength: {
                            value: 600,
                            message:
                              "This field only allowed only 600 characters",
                          },
                          pattern: {
                            value: "/^[a-zA-Z0-9/()]+$/",
                            message:
                              "This field only allows letters and numbers.",
                          },
                        })}
                      />
                      {errors.reasonForLeaving && (
                        <ul
                          className="list-unstyled text-sm mt-1 text-muted filled"
                          id="parsley-id-119"
                        >
                          <li className="parsley-required text-danger">
                            {errors.reasonForLeaving?.message}
                          </li>
                        </ul>
                      )}
                    </Col>
                  </FormGroup>
                  <hr />
                  <div className="col-md-12 d-flex justify-content-between align-items-center mb-3">
                    <h5>Candidate Assessment </h5>
                  </div>

                  {questions?.map((question, id) => {
                    return (
                      <FormGroup row key={id} className="m-3">
                        <Col sm={12} md={12} lg={4}>
                          <Label>
                            {id + 1}. {question?.question}
                          </Label>
                          {errors[question.questionNo] && (
                            <ul
                              className="list-unstyled text-sm mt-1 text-muted filled"
                              id="parsley-id-119"
                            >
                              <li className="parsley-required text-danger">
                                {errors[question.questionNo].message}
                              </li>
                            </ul>
                          )}
                        </Col>
                        <Col sm={12} md={12} lg={2}>
                          <FormGroup check>
                            <Label>
                              <Input
                                type="radio"
                                className="pointer"
                                name={question?.questionNo}
                                value={4}
                                checked={
                                  referenceForm &&
                                  checkBoxStatus(
                                    question?.questionNo,
                                    "VERY_GOOD"
                                  )
                                }
                                innerRef={register({
                                  required: {
                                    value: true,
                                    message: "This field is required!",
                                  },
                                })}
                              />
                              Excellent
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col
                          sm={12}
                          md={12}
                          lg={1}
                          // style={{ marginLeft: "-10px", marginRight: "5px" }}
                        >
                          <FormGroup check>
                            <Label>
                              <Input
                                type="radio"
                                className="pointer"
                                name={question?.questionNo}
                                value={3}
                                checked={
                                  referenceForm &&
                                  checkBoxStatus(question?.questionNo, "GOOD")
                                }
                                innerRef={register({
                                  required: {
                                    value: true,
                                    message: "This field is required!",
                                  },
                                })}
                              />
                              Good
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col sm={12} md={12} lg={2}>
                          <FormGroup check>
                            <Label>
                              <Input
                                type="radio"
                                className="pointer"
                                name={question?.questionNo}
                                value={2}
                                checked={
                                  referenceForm &&
                                  checkBoxStatus(
                                    question?.questionNo,
                                    "SATISFACTORY"
                                  )
                                }
                                innerRef={register({
                                  required: {
                                    value: true,
                                    message: "This field is required!",
                                  },
                                })}
                              />
                              Satisfactory
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col sm={12} md={12} lg={1}>
                          <FormGroup check>
                            <Label>
                              <Input
                                type="radio"
                                className="pointer"
                                name={question?.questionNo}
                                value={1}
                                checked={
                                  referenceForm &&
                                  checkBoxStatus(question?.questionNo, "POOR")
                                }
                                innerRef={register({
                                  required: {
                                    value: true,
                                    message: "This field is required!",
                                  },
                                })}
                              />
                              Poor
                            </Label>
                          </FormGroup>
                        </Col>

                        <Col sm={12} md={12} lg={2}>
                          <FormGroup check>
                            <Label>
                              <Input
                                type="radio"
                                className="pointer"
                                name={question?.questionNo}
                                value={0}
                                checked={
                                  referenceForm &&
                                  checkBoxStatus(
                                    question?.questionNo,
                                    "VERY_POOR"
                                  )
                                }
                                innerRef={register({
                                  required: {
                                    value: true,
                                    message: "This field is required!",
                                  },
                                })}
                              />
                              Unsatisfactory
                            </Label>
                          </FormGroup>
                        </Col>
                      </FormGroup>
                    );
                  })}
                  <hr />
                  <div className=" col-md-12 d-flex justify-content-between align-items-center mb-3">
                    <h5>How Would You Rate This Candidate Overall?</h5>
                  </div>
                  <FormGroup row className="m-3">
                    <Col sm={12} md={12} lg={4}>
                      <Label> Rating</Label>
                      {errors.rating && (
                        <ul
                          className="list-unstyled text-sm mt-1 text-muted filled"
                          id="parsley-id-119"
                        >
                          <li className="parsley-required text-danger">
                            {errors.rating?.message}
                          </li>
                        </ul>
                      )}
                    </Col>
                    <Col sm={12} md={12} lg={2}>
                      <FormGroup check>
                        <Label>
                          <Input
                            type="radio"
                            className="pointer"
                            name="rating"
                            value={"VERY_GOOD"}
                            invalid={errors.rating}
                            checked={
                              referenceForm &&
                              referenceForm?.rating == "VERY_GOOD"
                            }
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />
                          Excellent
                        </Label>
                      </FormGroup>
                    </Col>

                    <Col
                      sm={12}
                      md={12}
                      lg={1}
                      // style={{ marginLeft: "-10px", marginRight: "5px" }}
                    >
                      <FormGroup check>
                        <Label>
                          <Input
                            type="radio"
                            className="pointer"
                            name="rating"
                            value={"GOOD"}
                            invalid={errors.rating}
                            checked={
                              referenceForm && referenceForm?.rating == "GOOD"
                            }
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />
                          Good
                        </Label>
                      </FormGroup>
                    </Col>

                    <Col sm={12} md={12} lg={2}>
                      <FormGroup check>
                        <Label>
                          <Input
                            type="radio"
                            className="pointer"
                            name="rating"
                            value={"SATISFACTORY"}
                            invalid={errors.rating}
                            checked={
                              referenceForm &&
                              referenceForm?.rating == "SATISFACTORY"
                            }
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />
                          Satisfactory
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col sm={12} md={12} lg={1}>
                      <FormGroup check>
                        <Label>
                          <Input
                            type="radio"
                            className="pointer"
                            name="rating"
                            value={"POOR"}
                            invalid={errors.rating}
                            checked={
                              referenceForm && referenceForm?.rating == "POOR"
                            }
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />
                          Poor
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col sm={2} md={2}>
                      <FormGroup check>
                        <Label>
                          <Input
                            type="radio"
                            className="pointer"
                            name={"rating"}
                            value={"VERY_POOR"}
                            invalid={errors.rating}
                            checked={
                              referenceForm &&
                              referenceForm?.rating == "VERY_POOR"
                            }
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />
                          Unsatisfactory
                        </Label>
                      </FormGroup>
                    </Col>
                  </FormGroup>
                  <FormGroup row className="m-3">
                    <Col sm={12} md={12} lg={8}>
                      <Label>Would you re-employ this candidate?</Label>
                      {errors.reEmployCandidate && (
                        <ul
                          className="list-unstyled text-sm mt-1 text-muted filled"
                          id="parsley-id-119"
                        >
                          <li className="parsley-required text-danger">
                            {errors.reEmployCandidate?.message}
                          </li>
                        </ul>
                      )}
                    </Col>
                    <Col sm={2} md={2}>
                      <FormGroup check>
                        <Label>
                          <Input
                            type="radio"
                            className="pointer"
                            name={"reEmployCandidate"}
                            value={"true"}
                            invalid={errors.reEmployCandidate}
                            checked={
                              referenceForm && referenceForm?.reEmployCandidate
                            }
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />
                          Yes
                        </Label>
                      </FormGroup>
                    </Col>

                    <Col sm={12} md={12} lg={2}>
                      <FormGroup check>
                        <Label>
                          <Input
                            type="radio"
                            className="pointer"
                            name={"reEmployCandidate"}
                            invalid={errors.reEmployCandidate}
                            value={"false"}
                            checked={
                              referenceForm && !referenceForm?.reEmployCandidate
                            }
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />
                          No
                        </Label>
                      </FormGroup>
                    </Col>
                  </FormGroup>
                  <hr />
                  <Col sm={12} md={12} lg={12}>
                    <Alert className="text-justify">
                      In order to protect the public, the post for which the
                      application is being made is exempt from Section 4 (2) of
                      the Rehabilitation of Offenders Act 1974 by virtue of the
                      Rehabilitation of Offenders Act 1974 (Exceptions) Order
                      1975. It is not therefore in any way contrary to the Act
                      to reveal any information you may have concerning
                      convictions which would otherwise be considered as 'spent'
                      in relation to this application and which you consider
                      relevant to the applicant's suitability for employment.
                      Any such information will be kept in strictest confidence
                      and used only in consideration of the suitability of this
                      applicant for a position where such an exemption is
                      appropriate.
                    </Alert>
                  </Col>

                  <hr />

                  <div className="col-md-12 d-flex justify-content-between align-items-center">
                    <h5>Your Signature </h5>
                  </div>
                  <FormGroup row className="m-3">
                    <Col sm={12} md={12} lg={6}>
                      <Label>Referee Name</Label>
                      <Input
                        type="name"
                        name="referenceName"
                        placeholder="Referee's Name"
                        invalid={errors.telephoneNo}
                        defaultValue={
                          referenceForm && referenceForm?.referenceName
                        }
                        innerRef={register({
                          required: {
                            value: true,
                            message: "This field is required!",
                          },
                          maxLength: {
                            value: 100,
                            message:
                              "This field only allowed only 100 characters",
                          },
                          pattern: {
                            value: "/^[a-zA-Z0-9/()]+$/",
                            message:
                              "This field only allows letters and numbers.",
                          },
                        })}
                      />
                      {errors.referenceName && (
                        <ul
                          className="list-unstyled text-sm mt-1 text-muted filled"
                          id="parsley-id-119"
                        >
                          <li className="parsley-required text-danger">
                            {errors.referenceName?.message}
                          </li>
                        </ul>
                      )}
                    </Col>
                    <Col sm={12} md={12} lg={6}>
                      <Label>Telephone Number </Label>
                      <Input
                        type="text"
                        name="telephoneNo"
                        placeholder="Telephone Number"
                        invalid={errors.telephoneNo}
                        defaultValue={
                          referenceForm && referenceForm?.telephoneNo
                        }
                        innerRef={register({
                          required: {
                            value: true,
                            message: "This field is required!",
                          },
                          maxLength: {
                            value: 20,
                            message:
                              "This field only allowed only 20 characters",
                          },
                          // pattern: {
                          //   value:
                          //     /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/,
                          //   message: "Please enter a valid phone number",
                          // },
                        })}
                      />
                      {errors.telephoneNo && (
                        <ul
                          className="list-unstyled text-sm mt-1 text-muted filled"
                          id="parsley-id-119"
                        >
                          <li className="parsley-required text-danger">
                            {errors.telephoneNo?.message}
                          </li>
                          <li className="parsley-required text-danger">
                            +44 xxxx xxxx xxx
                          </li>
                          <li className="parsley-required text-danger">
                            0xxxx xxxx xxx
                          </li>
                        </ul>
                      )}
                    </Col>
                  </FormGroup>
                  <FormGroup row className="m-3">
                    <Col sm={12} md={12} lg={12}>
                      <Label>Referee Email </Label>

                      <Input
                        type="Email"
                        name={"refereeEmail"}
                        placeholder="Refree Email"
                        defaultValue={
                          referenceForm && referenceForm?.refereeEmail
                        }
                        invalid={errors.refereeEmail}
                        innerRef={register({
                          required: {
                            value: true,
                            message: "This field is required!",
                          },
                          maxLength: {
                            value: 50,
                            message:
                              "This field only allowed only 50 characters",
                          },
                          pattern: {
                            value:
                              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Please enter a valid email address!",
                          },
                        })}
                      />
                      {errors.refereeEmail && (
                        <ul
                          className="list-unstyled text-sm mt-1 text-muted filled"
                          id="parsley-id-119"
                        >
                          <li className="parsley-required text-danger">
                            {errors.refereeEmail?.message}
                          </li>
                        </ul>
                      )}
                    </Col>
                  </FormGroup>
                  <FormGroup row className="m-3">
                    <Col sm={12} md={4} lg={4}>
                      <Label>Is this a work email Address ?</Label>
                    </Col>

                    <Col sm={12} md={4} lg={4}>
                      <FormGroup check>
                        <Label>
                          <Input
                            type="radio"
                            className="pointer"
                            name={"IsWorkEmail"}
                            onChange={() => setIsWorkEmail(true)}
                            value={true}
                            invalid={errors.IsWorkEmail}
                            checked={referenceForm && referenceForm?.workEmail}
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />
                          Yes
                        </Label>
                      </FormGroup>
                    </Col>

                    <Col sm={12} md={4} lg={4}>
                      <FormGroup check>
                        <Label>
                          <Input
                            type="radio"
                            className="pointer"
                            name={"IsWorkEmail"}
                            onChange={() => setIsWorkEmail(false)}
                            invalid={errors.IsWorkEmail}
                            value={false}
                            checked={referenceForm && !referenceForm?.workEmail}
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />
                          No
                        </Label>
                      </FormGroup>
                      {errors.IsWorkEmail && (
                        <ul
                          className="list-unstyled text-sm mt-1 text-muted filled"
                          id="parsley-id-119"
                        >
                          <li className="parsley-required text-danger">
                            {errors.IsWorkEmail?.message}
                          </li>
                        </ul>
                      )}
                    </Col>
                  </FormGroup>

                  <FormGroup row className="m-3">
                    <Col sm={12} md={12} lg={8}>
                      <Label>Address</Label>
                      <Input
                        type="text"
                        name="address"
                        placeholder="Refree Address"
                        defaultValue={referenceForm && referenceForm?.address}
                        invalid={errors.address}
                        innerRef={register({
                          required: {
                            value: true,
                            message: "This field is required!",
                          },
                          maxLength: {
                            value: 200,
                            message:
                              "This field only allowed only 200 characters",
                          },
                        })}
                      />
                      {errors.address && (
                        <ul
                          className="list-unstyled text-sm mt-1 text-muted filled"
                          id="parsley-id-119"
                        >
                          <li className="parsley-required text-danger">
                            {errors.address?.message}
                          </li>
                        </ul>
                      )}
                    </Col>
                    <Col sm={12} md={12} lg={4}>
                      <Label>Post Code</Label>
                      <Input
                        type="text"
                        name="postCode"
                        placeholder="Postal code"
                        defaultValue={referenceForm && referenceForm?.postCode}
                        invalid={errors.postCode}
                        innerRef={register({
                          required: {
                            value: true,
                            message: "This field is required!",
                          },
                          maxLength: {
                            value: 30,
                            message:
                              "This field only allowed only 30 characters",
                          },
                        })}
                      />

                      {errors.postCode && (
                        <ul
                          className="list-unstyled text-sm mt-1 text-muted filled"
                          id="parsley-id-119"
                        >
                          <li className="parsley-required text-danger">
                            {errors.postCode?.message}
                          </li>
                        </ul>
                      )}
                    </Col>
                  </FormGroup>
                  <FormGroup row className="m-3">
                    <Col sm={12} md={12} lg={4}>
                      <Label for="band">
                        Band <span className="text-danger">*</span>
                      </Label>
                      <Input
                        // disabled={mode === "POST" ? false : true}
                        type="select"
                        id="band"
                        name="band"
                        // defaultValue={referenceForm ? referenceForm?.band : -1}
                        value={selectedBand}
                        invalid={errors.band || bandError}
                        innerRef={register({
                          required: {
                            value: true,
                            message: "This field is required!",
                          },
                        })}
                        onChange={(e) => {
                          setBandError(false);
                          setSelectedBand(e.target.value);
                        }}
                      >
                        <option value={-1}>Select</option>
                        {bands &&
                          bands.map((band, id) => {
                            return (
                              <option
                                value={band}
                                key={id}
                                style={{ color: "#6A6767" }}
                              >
                                {band}
                              </option>
                            );
                          })}
                      </Input>
                      {(errors.band || bandError) && (
                        <ul
                          className="list-unstyled text-sm mt-1 text-muted filled"
                          id="parsley-id-119"
                        >
                          <li className="parsley-required text-danger">
                            {errors.band?.message}
                          </li>
                        </ul>
                      )}
                    </Col>
                  </FormGroup>
                  {mode == "POST" && (
                    <div className="card-header">
                      <div className="col-md-12  ">
                        <Alert
                          className="col-md-12 d-flex justify-content-center align-items-center"
                          color={signedError ? "danger" : "info"}
                        >
                          <div>
                            <b>
                              <h4>Signature:</h4>
                            </b>
                            <small className="mb-3">
                              By signing this reference form, I declare that all
                              the information provide is accurate and factual to
                              my knowledge and that this information may be
                              shared with employers.
                            </small>
                          </div>
                          <div className="round mb-1 mx-4 mt-3">
                            <input
                              type="checkbox"
                              id="signature"
                              checked={signed}
                              onChange={() => {
                                setSignedError(false);
                                setSigned(!signed);
                              }}
                            />
                            <label for="signature"></label>
                          </div>
                        </Alert>
                        {signedError && (
                          <ul
                            className="list-unstyled text-sm mt-1 text-muted filled"
                            id="parsley-id-119"
                          >
                            <li className="parsley-required text-danger">
                              Please check the "Checkbox" above to provide your
                              consent.
                            </li>
                          </ul>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                {mode == "POST" && !IsWorkEmail && (
                  <div className="card-header">
                    <div className="col-md-12  ">
                      <Alert className="col-md-12 d-flex justify-content-start align-items-center ">
                        <div>
                          <b>
                            <h4>Note:</h4>
                          </b>
                          <small className="mb-3">
                            Please be kind enough to download the reference form
                            from here, fill it out, stamp it from your hospital,
                            and send it to {CommonEmailReplySignatureEmail}.
                          </small>
                          <br />
                          <br />
                          <small>Stamp:</small>
                          <div
                            style={{
                              height: "100px",
                              width: "100%",
                              border: "1px solid",
                              padding: "5px",
                            }}
                          ></div>
                        </div>
                      </Alert>
                    </div>
                  </div>
                )}

                <div>
                  {Object.keys(errors).length !== 0 && (
                    <div className="card-header">
                      <div className="col-md-12  ">
                        <Alert
                          color="danger"
                          className="col-md-12 d-flex justify-content-start align-items-center   "
                        >
                          <div>
                            <b>
                              <h4 className="text-danger">Error:</h4>
                            </b>
                            <li className="mb-3">
                              One of more field(s) are not valid.
                            </li>
                            {employeementDateError ? (
                              <li>
                                Please check the employment start and
                                termination dates.
                              </li>
                            ) : null}
                          </div>
                        </Alert>
                      </div>
                    </div>
                  )}
                </div>
              </>

              <div className="d-flex justify-content-between my-4">
                <div></div>
                <div className="d-flex align-items-center">
                  {mode === "POST" ? (
                    IsWorkEmail ? (
                      <>
                        <style>
                          {`
                      @media print {
                        #saveButton {
                          display: none;
                        }
                      }
                    `}
                        </style>
                        <Button
                          className="mr-2 btn-md"
                          color="success"
                          type="button"
                          id="downloadButton"
                          onClick={downloadRefForm}
                        >
                          Download
                        </Button>
                        <Button
                          className="mr-4 btn-md"
                          color="success"
                          id="saveButton"
                          type="submit"
                        >
                          Save
                        </Button>
                      </>
                    ) : (
                      <>
                        <style>
                          {`
                      @media print {
                        #saveButton1 {
                          display: none;
                        }
                      }
                    `}
                        </style>
                        <Button
                          className="mr-2 btn-md"
                          color="success"
                          type="button"
                          id="downloadButton"
                          onClick={downloadRefForm}
                        >
                          Download
                        </Button>
                        <Button
                          className="mr-4 btn-md"
                          color="success"
                          id="saveButton1"
                          type="submit"
                        >
                          Save
                        </Button>
                      </>
                    )
                  ) : (
                    <Button
                      className="mr-2"
                      color="secondary"
                      onClick={closeModal}
                    >
                      Close
                    </Button>
                  )}

                  {referenceForm?.referenceStatus === "SUBMITTED" && (
                    <div>
                      <Button
                        className="mr-2"
                        color="danger"
                        onClick={() => {
                          setShowRejectForm(true);
                        }}
                      >
                        Reject
                      </Button>
                      <Button
                        className="mr-2"
                        color="success"
                        onClick={() => {
                          setShowApprovedForm(true);
                        }}
                      >
                        Approve
                      </Button>
                    </div>
                  )}

                  {referenceForm?.referenceStatus === "APPROVED" && (
                    <div>
                      <Button
                        className="mr-2 "
                        color="success"
                        type="button"
                        id="downloadButton"
                        onClick={generatePDF}
                      >
                        Download
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>

      <ModalComponent
        show={showResubmitForm}
        header={`Resubmit Form`}
        className={`modal-dialog modal-custom ${
          showResubmitForm ? "modal-dialog-centered" : ""
        }`}
        closeModal={() => {
          setShowResubmitForm(false);
        }}
      >
        <ResubmitForm
          rowID={referenceForm?.id}
          mode="Resubmit"
          referenceForm={referenceForm}
          // auth={auth?.item.id}
          // rowvalue={rowvalue}
          // rowNoteID={rowNoteID}
          // action={getNotes}
          // success={() => {
          //   // setReset(!reset);
          //   setShowUpdateRow(false);
          // }}
          closeModal1={() => {
            setShowResubmitForm(false);
          }}
        />
      </ModalComponent>

      <Approve
        show={showApprovedForm}
        isConfirm={() => {
          ApproveForm();
          setShowApprovedForm(!showApprovedForm);
        }}
        closeModal={() => setShowApprovedForm(false)}
        getData={getData}
      />

      <ModalComponent
        show={showRejectForm}
        header={`Reject Form`}
        className={`modal-dialog modal-custom ${
          showRejectForm ? "modal-dialog-centered" : ""
        }`}
        closeModal={() => {
          setShowRejectForm(false);
        }}
      >
        <RejectForm
          rowID={referenceForm?.id}
          referenceForm={referenceForm}
          mode="Reject"
          reset={reset}
          // auth={auth?.item.id}
          // rowvalue={rowvalue}
          // rowNoteID={rowNoteID}
          // action={getNotes}
          // success={() => {
          //   // setReset(!reset);
          //   setShowUpdateRow(false);
          // }}
          closeModa12={() => {
            setShowRejectForm(false);
          }}
          // getData={getData}
        />
      </ModalComponent>
    </div>
  );
}
function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(ReferenceForm);
