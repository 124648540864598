import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Label, Input, Form, FormGroup, Col, Row } from "reactstrap";
import axios from "axios";
import ToastCommon from "../../../toastCommon";
import { useToasts } from "react-toast-notifications";
import { HOSTMexxar } from "../../../../configs/api-config";
import moment from "moment";
import { sendNotificationWeb } from "../../../../util/common/sendNotificationWeb";
import { sendNotification } from "../../../../util/common/sendNotification";
import { connect } from "react-redux";

function ResubmitForm(props) {
  const { register, handleSubmit, errors } = useForm();
  const [reason, setReason] = useState("");
  const [resubmitDisabled, setResubmitDisabled] = useState(true);
  const { addToast } = useToasts();
  const [candidateId, setCandidateId] = useState(false);
  const [candidateName, setCandidateName] = useState(false);
  const { closeModal1, rowID, reset, auth, referenceForm } = props;

  const changeHandler = (event) => {
    const { value } = event.target;
    const trimmedValue = value.trim();
    setReason(trimmedValue);
    setResubmitDisabled(trimmedValue === "");
  };

  const resubmitForm = () => {
    axios
      .patch(`${HOSTMexxar}reference-form/resubmit/${rowID}?body=${reason}`)
      .then((res) => {
        setCandidateId(res?.data?.body?.candidate?.id);
        setCandidateName(res?.data?.body?.candidate?.candidateName);
        if (res.data.status === "success") {
          // Handle success
          reset();

          sendNotification({
            notificationType: "NOTICE",
            title: "Reference form",
            content: `Hi,${candidateName}'s reference form is Resubmited`,
            candidateId: auth?.item?.id,
            startDate: moment().toISOString(),
            addToast,
          });

          sendNotification({
            notificationType: "NOTICE",
            title: "Reference form",
            content: `Hi, ${candidateName}'s reference form is Resubmited`,
            candidateId: candidateId,
            startDate: moment().toISOString(),
            addToast,
          });

          sendNotificationWeb({
            action: "Resubmited",
            tittle: "Reference form",
            body: `Hi, ${candidateName}'s reference form is Resubmited`,
            userId: candidateId,
            date: moment().toISOString(),
          });
          sendNotificationWeb({
            action: "Resubmited",
            tittle: "Reference form",
            body: `Hi, ${candidateName}'s reference form is Resubmited`,
            userId: auth?.item?.id,
            date: moment().toISOString(),
          });
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };

  return (
    <Form>
      <FormGroup row>
        <Label sm={4}>Reason</Label>
        <Col sm={8}>
          <Input
            onChange={changeHandler}
            type="text"
            name="reason"
            invalid={errors.reason}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
              maxLength: {
                value: 30,
                message: "This field only allows 30 characters",
              },
            })}
          />
          {errors.reason && (
            <ul
              className="list-unstyled text-sm mt-1 text-muted filled"
              id="parsley-id-119"
            >
              <li className="parsley-required text-danger">
                {errors.reason.message}
              </li>
            </ul>
          )}
        </Col>
      </FormGroup>
      <Row className="justify-content-end">
        <div className="d-flex">
          <Button
            className="mr-2"
            color="success"
            onClick={() => {
              resubmitForm();
              closeModal1();
            }}
            disabled={resubmitDisabled}
          >
            Resubmit
          </Button>
          <Button color="secondary" onClick={() => closeModal1()}>
            Close
          </Button>
        </div>
      </Row>
    </Form>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(ResubmitForm);
